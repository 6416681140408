/*-------------------------------------------------------*/
/* Block Editor Front-end Styles
/*-------------------------------------------------------*/

/* Buttons
-------------------------------------------------------*/
.wp-block-button .wp-block-button__link {
	padding: 14px 32px;
	font-size: 0.9375rem;
	border-radius: 5px;
	font-weight: 700;
	line-height: 1.5 !important;
}

.wp-block-button__link:not(.has-background),
.wp-block-button__link:not(.has-background):active,
.wp-block-button__link:not(.has-background):focus,
.wp-block-button__link:not(.has-background):hover {
	background-color: $primary-color;
}


/* Full-width Images
-------------------------------------------------------*/
@include bp-lg-up {

	.dorya-fullwidth {
		.entry__article {
			.alignwide {
				margin-left: -140px;
				margin-right: -140px;
				width: auto;
				position: relative;
				z-index: 1;
			}

			.alignfull {
				margin-left: calc( 50% - 50vw );
				margin-right: calc( 50% - 50vw );
				max-width: 100vw;
				position: relative;
				z-index: 1;

				img {
					width: 100vw;
				}
			}
		}
	}
}


/* Images
-------------------------------------------------------*/
.wp-block-image {
	margin-top: 40px;
	margin-bottom: 40px;

	@include bp-lg-up {
		margin-top: 80px;
		margin-bottom: 80px;
	}

	.alignleft {
		margin-right: 2em;
		max-width: 40%;

		@include bp-sm-down {
			float: none;
			margin-right: 0;
		}
	}

	.alignright {
		margin-left: 2em;
		max-width: 40%;

		@include bp-sm-down {
			float: none;
			margin-left: 0;
		}
	}
}


/* Tables
-------------------------------------------------------*/
.wp-block-table {
	width: 100%;
	margin-bottom: 2rem;
}


/* Latest Comments
-------------------------------------------------------*/
ol.wp-block-latest-comments {
	padding-left: 0;
}


/* Galleries
-------------------------------------------------------*/
ul.wp-block-gallery {
	list-style: none;
	padding-left: 0 !important;
}

.wp-block-image figcaption {
	color: $meta-color;
}

.wp-block-gallery .blocks-gallery-image figcaption,
.wp-block-gallery .blocks-gallery-item figcaption {
	position: absolute;
	bottom: 0;
	width: 100%;
	max-height: 100%;
	overflow: auto;
	padding: 40px 10px 9px;
	color: #fff;
	text-align: center;
	font-size: 13px;
	background: linear-gradient(0deg,rgba(0,0,0,.7),rgba(0,0,0,.3) 70%,transparent);
}


/* Separators
-------------------------------------------------------*/
hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid $border-color;
}

.wp-block-separator.is-style-dots {
    background: 0 0;
    border: none;
    text-align: center;
    max-width: none;
    line-height: 1;
		height: auto;
		
		&::before {
			content: "\00b7 \00b7 \00b7";
			color: $meta-color;
			font-size: 1.25rem;
			font-weight: 700;
			letter-spacing: 2em;
			padding-left: 2em;
			font-family: serif;
		}
}

.wp-block-separator.is-style-default {
	max-width: 100px;
}

.wp-block-separator.is-style-dots:before {
	font-weight: 700;
}



/* Blockquote / Pullquote
-------------------------------------------------------*/
blockquote {
	margin: 0;
	padding-left: 40px;
	line-height: 1.4;
	
	p {
		margin-bottom: 0;
	}

  cite {
		color: $meta-color;
		@include fontSize( 16px );
		font-weight: 700;
		margin-top: 1em;
		display: inline-block;
		font-style: normal;
	}
	
	&::before {
		content: '“';
		font-family: $heading-font;
		@include fontSize( 44px );
		font-weight: 700;
		line-height: 1;
		display: block;
		position: absolute;
		left: 0;
	}
}

.wp-block-quote {
	margin-bottom: 40px;

	p {
		@include fontSize( 30px );
		font-weight: 700;
		font-family: $heading-font;
		color: $heading-color;
	}
}

.wp-block-pullquote {
	padding: 0;
	margin-bottom: 40px;
	text-align: left;
	color: $heading-color;
	position: relative;

	p {
		font-family: $heading-font;
		font-weight: 700;
	}

	@include bp-lg-up {
		&.alignleft,
		&.alignright {
			margin-top: 0;

			p {
				font-size: 1.25rem;
			}
		}

		&.alignleft {
			margin-left: -140px/*!rtl:end:ignore*/;
			margin-right: 2em/*!rtl:end:ignore*/;
		}

		&.alignright {
			margin-right: -140px/*!rtl:end:ignore*/;
			margin-left: 2em/*!rtl:end:ignore*/;
		}
	}

	@include bp-sm-down {
		&.alignleft,
		&.alignright {
			max-width: 100%;
			float: none;
			margin-left: 0;
			margin-right: 0;
		}
	}
}


// Solid Color
.wp-block-pullquote.is-style-solid-color {
	border: none;

	blockquote p {
		margin-bottom: 0;
	}

	&.alignleft blockquote p,
	&.alignright blockquote p {
		font-size: 1.25rem;
	}
}



// Gutenberg Styles (These styles are generated by WordPress now, so they can be deleted in WordPress version)

.wp-block-pullquote.is-style-solid-color blockquote {
	margin-left: auto;
	margin-right: auto;
	text-align: left;
	max-width: 60%;
}

.wp-block-gallery + .wp-block-gallery {
	margin-top: -40px;
}

.wp-block-gallery {
	display: flex;
	flex-wrap: wrap;
	list-style-type: none;
	padding: 0;
}

.wp-block-gallery .blocks-gallery-image, .wp-block-gallery .blocks-gallery-item {
	margin: 0 16px 16px 0;
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	justify-content: center;
	position: relative;
}

.wp-block-gallery .blocks-gallery-image:nth-of-type(even), .wp-block-gallery .blocks-gallery-item:nth-of-type(even) {
    margin-right: 0;
}

.wp-block-gallery .blocks-gallery-image, .wp-block-gallery .blocks-gallery-item {
	width: calc((100% - 16px)/ 2);
}


@media (min-width: 600px) {
	.wp-block-gallery.columns-3 .blocks-gallery-image, .wp-block-gallery.columns-3 .blocks-gallery-item {
		width: calc((100% - 16px * 2)/ 3);
		margin-right: 16px;
	}
}



.wp-block-gallery .blocks-gallery-image:last-child, .wp-block-gallery .blocks-gallery-item:last-child {
  margin-right: 0;
}

.wp-block-gallery .blocks-gallery-image figure, .wp-block-gallery .blocks-gallery-item figure {
  margin: 0;
  height: 100%;
}

.wp-block-gallery.is-cropped .blocks-gallery-image a, .wp-block-gallery.is-cropped .blocks-gallery-image img, .wp-block-gallery.is-cropped .blocks-gallery-item a, .wp-block-gallery.is-cropped .blocks-gallery-item img {
  width: 100%;
}

.wp-block-gallery .blocks-gallery-image figure, .wp-block-gallery .blocks-gallery-item figure {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}


.wp-block-columns {
    display: flex;
    flex-wrap: wrap;
}

@media (min-width: 782px) {
.wp-block-columns {
    flex-wrap: nowrap;
}
}

@media (min-width: 600px) {
.wp-block-column:not(:last-child) {
    margin-right: 32px;
}
}

@media (min-width: 600px) {
.wp-block-column:nth-child(odd) {
    margin-right: 32px;
}
}

@media (min-width: 600px) {
.wp-block-column {
    flex-basis: 50%;
    flex-grow: 0;
}
}

@media (min-width: 600px) {
.wp-block-column:not(:first-child) {
    margin-left: 32px;
}
}

.wp-block-column {
    flex: 1;
    margin-bottom: 1em;
    flex-basis: 100%;
    min-width: 0;
    word-break: break-word;
    overflow-wrap: break-word;
}