@charset "UTF-8";
/**
 * Convert font-size from px to rem with px fallback
 *
 * @param $size - the value in pixel you want to convert
 *
 * e.g. p {@include fontSize(12px);}
 * 
 */
/*-------------------------------------------------------*/
/* Table of Content

1. General
2. Typography
3. Icon Fonts
4. Grid
5. Navigation
6. Footer
7. Components
8. Pages
9. Helper Classes

/*-------------------------------------------------------*/
/*-------------------------------------------------------*/
/* Bootstrap Grid
/*-------------------------------------------------------*/
/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
*,
*::before,
*::after {
	box-sizing: border-box; }

html {
	font-family: sans-serif;
	line-height: 1.15;
	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
	display: block; }

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #212529;
	text-align: left;
	background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
	outline: 0 !important; }

hr {
	box-sizing: content-box;
	height: 0;
	overflow: visible; }

h1, h2, h3, h4, h5, h6 {
	margin-top: 0;
	margin-bottom: 0.5rem; }

p {
	margin-top: 0;
	margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
	text-decoration: underline;
	text-decoration: underline dotted;
	cursor: help;
	border-bottom: 0;
	text-decoration-skip-ink: none; }

address {
	margin-bottom: 1rem;
	font-style: normal;
	line-height: inherit; }

ol,
ul,
dl {
	margin-top: 0;
	margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
	margin-bottom: 0; }

dt {
	font-weight: 700; }

dd {
	margin-bottom: .5rem;
	margin-left: 0; }

blockquote {
	margin: 0 0 1rem; }

b,
strong {
	font-weight: bolder; }

small {
	font-size: 80%; }

sub,
sup {
	position: relative;
	font-size: 75%;
	line-height: 0;
	vertical-align: baseline; }

sub {
	bottom: -.25em; }

sup {
	top: -.5em; }

a {
	color: #007bff;
	text-decoration: none;
	background-color: transparent; }
	a:hover {
		color: #0056b3;
		text-decoration: underline; }

a:not([href]) {
	color: inherit;
	text-decoration: none; }
	a:not([href]):hover {
		color: inherit;
		text-decoration: none; }

pre,
code,
kbd,
samp {
	font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
	font-size: 1em; }

pre {
	margin-top: 0;
	margin-bottom: 1rem;
	overflow: auto;
	-ms-overflow-style: scrollbar; }

figure {
	margin: 0 0 1rem; }

img {
	vertical-align: middle;
	border-style: none; }

svg {
	overflow: hidden;
	vertical-align: middle; }

table {
	border-collapse: collapse; }

caption {
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
	color: #6c757d;
	text-align: left;
	caption-side: bottom; }

th {
	text-align: inherit; }

label {
	display: inline-block;
	margin-bottom: 0.5rem; }

button {
	border-radius: 0; }

button:focus {
	outline: 1px dotted;
	outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit; }

button,
input {
	overflow: visible; }

button,
select {
	text-transform: none; }

[role="button"] {
	cursor: pointer; }

select {
	word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
	-webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
	cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
	padding: 0;
	border-style: none; }

input[type="radio"],
input[type="checkbox"] {
	box-sizing: border-box;
	padding: 0; }

textarea {
	overflow: auto;
	resize: vertical; }

fieldset {
	min-width: 0;
	padding: 0;
	margin: 0;
	border: 0; }

legend {
	display: block;
	width: 100%;
	max-width: 100%;
	padding: 0;
	margin-bottom: .5rem;
	font-size: 1.5rem;
	line-height: inherit;
	color: inherit;
	white-space: normal; }

progress {
	vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
	height: auto; }

[type="search"] {
	outline-offset: -2px;
	-webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none; }

::-webkit-file-upload-button {
	font: inherit;
	-webkit-appearance: button; }

output {
	display: inline-block; }

summary {
	display: list-item;
	cursor: pointer; }

template {
	display: none; }

[hidden] {
	display: none !important; }

.container {
	width: 100%;
	padding-right: 10px;
	padding-left: 10px;
	margin-right: auto;
	margin-left: auto; }
	@media (min-width: 1025px) {
		.container {
			max-width: 940px; } }
	@media (min-width: 1440px) {
		.container {
			max-width: 1320px; } }

.container-fluid, .container-lg, .container-xl {
	width: 100%;
	padding-right: 10px;
	padding-left: 10px;
	margin-right: auto;
	margin-left: auto; }

@media (min-width: 1025px) {
	.container, .container-sm, .container-md, .container-lg {
		max-width: 940px; } }

@media (min-width: 1440px) {
	.container, .container-sm, .container-md, .container-lg, .container-xl {
		max-width: 1320px; } }

.row {
	display: flex;
	flex-wrap: wrap;
	margin-right: -10px;
	margin-left: -10px; }

.no-gutters {
	margin-right: 0;
	margin-left: 0; }
	.no-gutters > .col,
	.no-gutters > [class*="col-"] {
		padding-right: 0;
		padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
	position: relative;
	width: 100%;
	padding-right: 10px;
	padding-left: 10px; }

.col {
	flex-basis: 0;
	flex-grow: 1;
	min-width: 0;
	max-width: 100%; }

.row-cols-1 > * {
	flex: 0 0 100%;
	max-width: 100%; }

.row-cols-2 > * {
	flex: 0 0 50%;
	max-width: 50%; }

.row-cols-3 > * {
	flex: 0 0 33.33333%;
	max-width: 33.33333%; }

.row-cols-4 > * {
	flex: 0 0 25%;
	max-width: 25%; }

.row-cols-5 > * {
	flex: 0 0 20%;
	max-width: 20%; }

.row-cols-6 > * {
	flex: 0 0 16.66667%;
	max-width: 16.66667%; }

.col-auto {
	flex: 0 0 auto;
	width: auto;
	max-width: 100%; }

.col-1 {
	flex: 0 0 8.33333%;
	max-width: 8.33333%; }

.col-2 {
	flex: 0 0 16.66667%;
	max-width: 16.66667%; }

.col-3 {
	flex: 0 0 25%;
	max-width: 25%; }

.col-4 {
	flex: 0 0 33.33333%;
	max-width: 33.33333%; }

.col-5 {
	flex: 0 0 41.66667%;
	max-width: 41.66667%; }

.col-6 {
	flex: 0 0 50%;
	max-width: 50%; }

.col-7 {
	flex: 0 0 58.33333%;
	max-width: 58.33333%; }

.col-8 {
	flex: 0 0 66.66667%;
	max-width: 66.66667%; }

.col-9 {
	flex: 0 0 75%;
	max-width: 75%; }

.col-10 {
	flex: 0 0 83.33333%;
	max-width: 83.33333%; }

.col-11 {
	flex: 0 0 91.66667%;
	max-width: 91.66667%; }

.col-12 {
	flex: 0 0 100%;
	max-width: 100%; }

.order-first {
	order: -1; }

.order-last {
	order: 13; }

.order-0 {
	order: 0; }

.order-1 {
	order: 1; }

.order-2 {
	order: 2; }

.order-3 {
	order: 3; }

.order-4 {
	order: 4; }

.order-5 {
	order: 5; }

.order-6 {
	order: 6; }

.order-7 {
	order: 7; }

.order-8 {
	order: 8; }

.order-9 {
	order: 9; }

.order-10 {
	order: 10; }

.order-11 {
	order: 11; }

.order-12 {
	order: 12; }

.offset-1 {
	margin-left: 8.33333%; }

.offset-2 {
	margin-left: 16.66667%; }

.offset-3 {
	margin-left: 25%; }

.offset-4 {
	margin-left: 33.33333%; }

.offset-5 {
	margin-left: 41.66667%; }

.offset-6 {
	margin-left: 50%; }

.offset-7 {
	margin-left: 58.33333%; }

.offset-8 {
	margin-left: 66.66667%; }

.offset-9 {
	margin-left: 75%; }

.offset-10 {
	margin-left: 83.33333%; }

.offset-11 {
	margin-left: 91.66667%; }

@media (min-width: 576px) {
	.col-sm {
		flex-basis: 0;
		flex-grow: 1;
		min-width: 0;
		max-width: 100%; }
	.row-cols-sm-1 > * {
		flex: 0 0 100%;
		max-width: 100%; }
	.row-cols-sm-2 > * {
		flex: 0 0 50%;
		max-width: 50%; }
	.row-cols-sm-3 > * {
		flex: 0 0 33.33333%;
		max-width: 33.33333%; }
	.row-cols-sm-4 > * {
		flex: 0 0 25%;
		max-width: 25%; }
	.row-cols-sm-5 > * {
		flex: 0 0 20%;
		max-width: 20%; }
	.row-cols-sm-6 > * {
		flex: 0 0 16.66667%;
		max-width: 16.66667%; }
	.col-sm-auto {
		flex: 0 0 auto;
		width: auto;
		max-width: 100%; }
	.col-sm-1 {
		flex: 0 0 8.33333%;
		max-width: 8.33333%; }
	.col-sm-2 {
		flex: 0 0 16.66667%;
		max-width: 16.66667%; }
	.col-sm-3 {
		flex: 0 0 25%;
		max-width: 25%; }
	.col-sm-4 {
		flex: 0 0 33.33333%;
		max-width: 33.33333%; }
	.col-sm-5 {
		flex: 0 0 41.66667%;
		max-width: 41.66667%; }
	.col-sm-6 {
		flex: 0 0 50%;
		max-width: 50%; }
	.col-sm-7 {
		flex: 0 0 58.33333%;
		max-width: 58.33333%; }
	.col-sm-8 {
		flex: 0 0 66.66667%;
		max-width: 66.66667%; }
	.col-sm-9 {
		flex: 0 0 75%;
		max-width: 75%; }
	.col-sm-10 {
		flex: 0 0 83.33333%;
		max-width: 83.33333%; }
	.col-sm-11 {
		flex: 0 0 91.66667%;
		max-width: 91.66667%; }
	.col-sm-12 {
		flex: 0 0 100%;
		max-width: 100%; }
	.order-sm-first {
		order: -1; }
	.order-sm-last {
		order: 13; }
	.order-sm-0 {
		order: 0; }
	.order-sm-1 {
		order: 1; }
	.order-sm-2 {
		order: 2; }
	.order-sm-3 {
		order: 3; }
	.order-sm-4 {
		order: 4; }
	.order-sm-5 {
		order: 5; }
	.order-sm-6 {
		order: 6; }
	.order-sm-7 {
		order: 7; }
	.order-sm-8 {
		order: 8; }
	.order-sm-9 {
		order: 9; }
	.order-sm-10 {
		order: 10; }
	.order-sm-11 {
		order: 11; }
	.order-sm-12 {
		order: 12; }
	.offset-sm-0 {
		margin-left: 0; }
	.offset-sm-1 {
		margin-left: 8.33333%; }
	.offset-sm-2 {
		margin-left: 16.66667%; }
	.offset-sm-3 {
		margin-left: 25%; }
	.offset-sm-4 {
		margin-left: 33.33333%; }
	.offset-sm-5 {
		margin-left: 41.66667%; }
	.offset-sm-6 {
		margin-left: 50%; }
	.offset-sm-7 {
		margin-left: 58.33333%; }
	.offset-sm-8 {
		margin-left: 66.66667%; }
	.offset-sm-9 {
		margin-left: 75%; }
	.offset-sm-10 {
		margin-left: 83.33333%; }
	.offset-sm-11 {
		margin-left: 91.66667%; } }

@media (min-width: 768px) {
	.col-md {
		flex-basis: 0;
		flex-grow: 1;
		min-width: 0;
		max-width: 100%; }
	.row-cols-md-1 > * {
		flex: 0 0 100%;
		max-width: 100%; }
	.row-cols-md-2 > * {
		flex: 0 0 50%;
		max-width: 50%; }
	.row-cols-md-3 > * {
		flex: 0 0 33.33333%;
		max-width: 33.33333%; }
	.row-cols-md-4 > * {
		flex: 0 0 25%;
		max-width: 25%; }
	.row-cols-md-5 > * {
		flex: 0 0 20%;
		max-width: 20%; }
	.row-cols-md-6 > * {
		flex: 0 0 16.66667%;
		max-width: 16.66667%; }
	.col-md-auto {
		flex: 0 0 auto;
		width: auto;
		max-width: 100%; }
	.col-md-1 {
		flex: 0 0 8.33333%;
		max-width: 8.33333%; }
	.col-md-2 {
		flex: 0 0 16.66667%;
		max-width: 16.66667%; }
	.col-md-3 {
		flex: 0 0 25%;
		max-width: 25%; }
	.col-md-4 {
		flex: 0 0 33.33333%;
		max-width: 33.33333%; }
	.col-md-5 {
		flex: 0 0 41.66667%;
		max-width: 41.66667%; }
	.col-md-6 {
		flex: 0 0 50%;
		max-width: 50%; }
	.col-md-7 {
		flex: 0 0 58.33333%;
		max-width: 58.33333%; }
	.col-md-8 {
		flex: 0 0 66.66667%;
		max-width: 66.66667%; }
	.col-md-9 {
		flex: 0 0 75%;
		max-width: 75%; }
	.col-md-10 {
		flex: 0 0 83.33333%;
		max-width: 83.33333%; }
	.col-md-11 {
		flex: 0 0 91.66667%;
		max-width: 91.66667%; }
	.col-md-12 {
		flex: 0 0 100%;
		max-width: 100%; }
	.order-md-first {
		order: -1; }
	.order-md-last {
		order: 13; }
	.order-md-0 {
		order: 0; }
	.order-md-1 {
		order: 1; }
	.order-md-2 {
		order: 2; }
	.order-md-3 {
		order: 3; }
	.order-md-4 {
		order: 4; }
	.order-md-5 {
		order: 5; }
	.order-md-6 {
		order: 6; }
	.order-md-7 {
		order: 7; }
	.order-md-8 {
		order: 8; }
	.order-md-9 {
		order: 9; }
	.order-md-10 {
		order: 10; }
	.order-md-11 {
		order: 11; }
	.order-md-12 {
		order: 12; }
	.offset-md-0 {
		margin-left: 0; }
	.offset-md-1 {
		margin-left: 8.33333%; }
	.offset-md-2 {
		margin-left: 16.66667%; }
	.offset-md-3 {
		margin-left: 25%; }
	.offset-md-4 {
		margin-left: 33.33333%; }
	.offset-md-5 {
		margin-left: 41.66667%; }
	.offset-md-6 {
		margin-left: 50%; }
	.offset-md-7 {
		margin-left: 58.33333%; }
	.offset-md-8 {
		margin-left: 66.66667%; }
	.offset-md-9 {
		margin-left: 75%; }
	.offset-md-10 {
		margin-left: 83.33333%; }
	.offset-md-11 {
		margin-left: 91.66667%; } }

@media (min-width: 1025px) {
	.col-lg {
		flex-basis: 0;
		flex-grow: 1;
		min-width: 0;
		max-width: 100%; }
	.row-cols-lg-1 > * {
		flex: 0 0 100%;
		max-width: 100%; }
	.row-cols-lg-2 > * {
		flex: 0 0 50%;
		max-width: 50%; }
	.row-cols-lg-3 > * {
		flex: 0 0 33.33333%;
		max-width: 33.33333%; }
	.row-cols-lg-4 > * {
		flex: 0 0 25%;
		max-width: 25%; }
	.row-cols-lg-5 > * {
		flex: 0 0 20%;
		max-width: 20%; }
	.row-cols-lg-6 > * {
		flex: 0 0 16.66667%;
		max-width: 16.66667%; }
	.col-lg-auto {
		flex: 0 0 auto;
		width: auto;
		max-width: 100%; }
	.col-lg-1 {
		flex: 0 0 8.33333%;
		max-width: 8.33333%; }
	.col-lg-2 {
		flex: 0 0 16.66667%;
		max-width: 16.66667%; }
	.col-lg-3 {
		flex: 0 0 25%;
		max-width: 25%; }
	.col-lg-4 {
		flex: 0 0 33.33333%;
		max-width: 33.33333%; }
	.col-lg-5 {
		flex: 0 0 41.66667%;
		max-width: 41.66667%; }
	.col-lg-6 {
		flex: 0 0 50%;
		max-width: 50%; }
	.col-lg-7 {
		flex: 0 0 58.33333%;
		max-width: 58.33333%; }
	.col-lg-8 {
		flex: 0 0 66.66667%;
		max-width: 66.66667%; }
	.col-lg-9 {
		flex: 0 0 75%;
		max-width: 75%; }
	.col-lg-10 {
		flex: 0 0 83.33333%;
		max-width: 83.33333%; }
	.col-lg-11 {
		flex: 0 0 91.66667%;
		max-width: 91.66667%; }
	.col-lg-12 {
		flex: 0 0 100%;
		max-width: 100%; }
	.order-lg-first {
		order: -1; }
	.order-lg-last {
		order: 13; }
	.order-lg-0 {
		order: 0; }
	.order-lg-1 {
		order: 1; }
	.order-lg-2 {
		order: 2; }
	.order-lg-3 {
		order: 3; }
	.order-lg-4 {
		order: 4; }
	.order-lg-5 {
		order: 5; }
	.order-lg-6 {
		order: 6; }
	.order-lg-7 {
		order: 7; }
	.order-lg-8 {
		order: 8; }
	.order-lg-9 {
		order: 9; }
	.order-lg-10 {
		order: 10; }
	.order-lg-11 {
		order: 11; }
	.order-lg-12 {
		order: 12; }
	.offset-lg-0 {
		margin-left: 0; }
	.offset-lg-1 {
		margin-left: 8.33333%; }
	.offset-lg-2 {
		margin-left: 16.66667%; }
	.offset-lg-3 {
		margin-left: 25%; }
	.offset-lg-4 {
		margin-left: 33.33333%; }
	.offset-lg-5 {
		margin-left: 41.66667%; }
	.offset-lg-6 {
		margin-left: 50%; }
	.offset-lg-7 {
		margin-left: 58.33333%; }
	.offset-lg-8 {
		margin-left: 66.66667%; }
	.offset-lg-9 {
		margin-left: 75%; }
	.offset-lg-10 {
		margin-left: 83.33333%; }
	.offset-lg-11 {
		margin-left: 91.66667%; } }

@media (min-width: 1440px) {
	.col-xl {
		flex-basis: 0;
		flex-grow: 1;
		min-width: 0;
		max-width: 100%; }
	.row-cols-xl-1 > * {
		flex: 0 0 100%;
		max-width: 100%; }
	.row-cols-xl-2 > * {
		flex: 0 0 50%;
		max-width: 50%; }
	.row-cols-xl-3 > * {
		flex: 0 0 33.33333%;
		max-width: 33.33333%; }
	.row-cols-xl-4 > * {
		flex: 0 0 25%;
		max-width: 25%; }
	.row-cols-xl-5 > * {
		flex: 0 0 20%;
		max-width: 20%; }
	.row-cols-xl-6 > * {
		flex: 0 0 16.66667%;
		max-width: 16.66667%; }
	.col-xl-auto {
		flex: 0 0 auto;
		width: auto;
		max-width: 100%; }
	.col-xl-1 {
		flex: 0 0 8.33333%;
		max-width: 8.33333%; }
	.col-xl-2 {
		flex: 0 0 16.66667%;
		max-width: 16.66667%; }
	.col-xl-3 {
		flex: 0 0 25%;
		max-width: 25%; }
	.col-xl-4 {
		flex: 0 0 33.33333%;
		max-width: 33.33333%; }
	.col-xl-5 {
		flex: 0 0 41.66667%;
		max-width: 41.66667%; }
	.col-xl-6 {
		flex: 0 0 50%;
		max-width: 50%; }
	.col-xl-7 {
		flex: 0 0 58.33333%;
		max-width: 58.33333%; }
	.col-xl-8 {
		flex: 0 0 66.66667%;
		max-width: 66.66667%; }
	.col-xl-9 {
		flex: 0 0 75%;
		max-width: 75%; }
	.col-xl-10 {
		flex: 0 0 83.33333%;
		max-width: 83.33333%; }
	.col-xl-11 {
		flex: 0 0 91.66667%;
		max-width: 91.66667%; }
	.col-xl-12 {
		flex: 0 0 100%;
		max-width: 100%; }
	.order-xl-first {
		order: -1; }
	.order-xl-last {
		order: 13; }
	.order-xl-0 {
		order: 0; }
	.order-xl-1 {
		order: 1; }
	.order-xl-2 {
		order: 2; }
	.order-xl-3 {
		order: 3; }
	.order-xl-4 {
		order: 4; }
	.order-xl-5 {
		order: 5; }
	.order-xl-6 {
		order: 6; }
	.order-xl-7 {
		order: 7; }
	.order-xl-8 {
		order: 8; }
	.order-xl-9 {
		order: 9; }
	.order-xl-10 {
		order: 10; }
	.order-xl-11 {
		order: 11; }
	.order-xl-12 {
		order: 12; }
	.offset-xl-0 {
		margin-left: 0; }
	.offset-xl-1 {
		margin-left: 8.33333%; }
	.offset-xl-2 {
		margin-left: 16.66667%; }
	.offset-xl-3 {
		margin-left: 25%; }
	.offset-xl-4 {
		margin-left: 33.33333%; }
	.offset-xl-5 {
		margin-left: 41.66667%; }
	.offset-xl-6 {
		margin-left: 50%; }
	.offset-xl-7 {
		margin-left: 58.33333%; }
	.offset-xl-8 {
		margin-left: 66.66667%; }
	.offset-xl-9 {
		margin-left: 75%; }
	.offset-xl-10 {
		margin-left: 83.33333%; }
	.offset-xl-11 {
		margin-left: 91.66667%; } }

.fade {
	transition: opacity 0.15s linear; }
	@media (prefers-reduced-motion: reduce) {
		.fade {
			transition: none; } }
	.fade:not(.show) {
		opacity: 0; }

.collapse:not(.show) {
	display: none; }

.collapsing {
	position: relative;
	height: 0;
	overflow: hidden;
	transition: height 0.35s ease; }
	@media (prefers-reduced-motion: reduce) {
		.collapsing {
			transition: none; } }

.close {
	float: right;
	font-size: 1.5rem;
	font-weight: 700;
	line-height: 1;
	color: #000;
	text-shadow: 0 1px 0 #fff;
	opacity: .5; }
	.close:hover {
		color: #000;
		text-decoration: none; }
	.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
		opacity: .75; }

button.close {
	padding: 0;
	background-color: transparent;
	border: 0; }

a.close.disabled {
	pointer-events: none; }

.modal-open {
	overflow: hidden; }
	.modal-open .modal {
		overflow-x: hidden;
		overflow-y: auto; }

.modal {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1050;
	display: none;
	width: 100%;
	height: 100%;
	overflow: hidden;
	outline: 0; }

.modal-dialog {
	position: relative;
	width: auto;
	margin: 0.5rem;
	pointer-events: none; }
	.modal.fade .modal-dialog {
		transition: transform 0.3s ease-out;
		transform: translate(0, -50px); }
		@media (prefers-reduced-motion: reduce) {
			.modal.fade .modal-dialog {
				transition: none; } }
	.modal.show .modal-dialog {
		transform: none; }
	.modal.modal-static .modal-dialog {
		transform: scale(1.02); }

.modal-dialog-scrollable {
	display: flex;
	max-height: calc(100% - 1rem); }
	.modal-dialog-scrollable .modal-content {
		max-height: calc(100vh - 1rem);
		overflow: hidden; }
	.modal-dialog-scrollable .modal-header,
	.modal-dialog-scrollable .modal-footer {
		flex-shrink: 0; }
	.modal-dialog-scrollable .modal-body {
		overflow-y: auto; }

.modal-dialog-centered {
	display: flex;
	align-items: center;
	min-height: calc(100% - 1rem); }
	.modal-dialog-centered::before {
		display: block;
		height: calc(100vh - 1rem);
		height: min-content;
		content: ""; }
	.modal-dialog-centered.modal-dialog-scrollable {
		flex-direction: column;
		justify-content: center;
		height: 100%; }
		.modal-dialog-centered.modal-dialog-scrollable .modal-content {
			max-height: none; }
		.modal-dialog-centered.modal-dialog-scrollable::before {
			content: none; }

.modal-content {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	pointer-events: auto;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: 0.3rem;
	outline: 0; }

.modal-backdrop {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1040;
	width: 100vw;
	height: 100vh;
	background-color: #000; }
	.modal-backdrop.fade {
		opacity: 0; }
	.modal-backdrop.show {
		opacity: 0.5; }

.modal-header {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	padding: 1rem 1rem;
	border-bottom: 1px solid #E9E9E9;
	border-top-left-radius: calc(0.3rem - 1px);
	border-top-right-radius: calc(0.3rem - 1px); }
	.modal-header .close {
		padding: 1rem 1rem;
		margin: -1rem -1rem -1rem auto; }

.modal-title {
	margin-bottom: 0;
	line-height: 1.5; }

.modal-body {
	position: relative;
	flex: 1 1 auto;
	padding: 1rem; }

.modal-footer {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: flex-end;
	padding: 0.75rem;
	border-top: 1px solid #E9E9E9;
	border-bottom-right-radius: calc(0.3rem - 1px);
	border-bottom-left-radius: calc(0.3rem - 1px); }
	.modal-footer > * {
		margin: 0.25rem; }

.modal-scrollbar-measure {
	position: absolute;
	top: -9999px;
	width: 50px;
	height: 50px;
	overflow: scroll; }

@media (min-width: 576px) {
	.modal-dialog {
		max-width: 500px;
		margin: 1.75rem auto; }
	.modal-dialog-scrollable {
		max-height: calc(100% - 3.5rem); }
		.modal-dialog-scrollable .modal-content {
			max-height: calc(100vh - 3.5rem); }
	.modal-dialog-centered {
		min-height: calc(100% - 3.5rem); }
		.modal-dialog-centered::before {
			height: calc(100vh - 3.5rem);
			height: min-content; }
	.modal-sm {
		max-width: 300px; } }

@media (min-width: 1025px) {
	.modal-lg,
	.modal-xl {
		max-width: 800px; } }

@media (min-width: 1440px) {
	.modal-xl {
		max-width: 1140px; } }

.d-none {
	display: none !important; }

.d-inline {
	display: inline !important; }

.d-inline-block {
	display: inline-block !important; }

.d-block {
	display: block !important; }

.d-table {
	display: table !important; }

.d-table-row {
	display: table-row !important; }

.d-table-cell {
	display: table-cell !important; }

.d-flex {
	display: flex !important; }

.d-inline-flex {
	display: inline-flex !important; }

@media (min-width: 576px) {
	.d-sm-none {
		display: none !important; }
	.d-sm-inline {
		display: inline !important; }
	.d-sm-inline-block {
		display: inline-block !important; }
	.d-sm-block {
		display: block !important; }
	.d-sm-table {
		display: table !important; }
	.d-sm-table-row {
		display: table-row !important; }
	.d-sm-table-cell {
		display: table-cell !important; }
	.d-sm-flex {
		display: flex !important; }
	.d-sm-inline-flex {
		display: inline-flex !important; } }

@media (min-width: 768px) {
	.d-md-none {
		display: none !important; }
	.d-md-inline {
		display: inline !important; }
	.d-md-inline-block {
		display: inline-block !important; }
	.d-md-block {
		display: block !important; }
	.d-md-table {
		display: table !important; }
	.d-md-table-row {
		display: table-row !important; }
	.d-md-table-cell {
		display: table-cell !important; }
	.d-md-flex {
		display: flex !important; }
	.d-md-inline-flex {
		display: inline-flex !important; } }

@media (min-width: 1025px) {
	.d-lg-none {
		display: none !important; }
	.d-lg-inline {
		display: inline !important; }
	.d-lg-inline-block {
		display: inline-block !important; }
	.d-lg-block {
		display: block !important; }
	.d-lg-table {
		display: table !important; }
	.d-lg-table-row {
		display: table-row !important; }
	.d-lg-table-cell {
		display: table-cell !important; }
	.d-lg-flex {
		display: flex !important; }
	.d-lg-inline-flex {
		display: inline-flex !important; } }

@media (min-width: 1440px) {
	.d-xl-none {
		display: none !important; }
	.d-xl-inline {
		display: inline !important; }
	.d-xl-inline-block {
		display: inline-block !important; }
	.d-xl-block {
		display: block !important; }
	.d-xl-table {
		display: table !important; }
	.d-xl-table-row {
		display: table-row !important; }
	.d-xl-table-cell {
		display: table-cell !important; }
	.d-xl-flex {
		display: flex !important; }
	.d-xl-inline-flex {
		display: inline-flex !important; } }

@media print {
	.d-print-none {
		display: none !important; }
	.d-print-inline {
		display: inline !important; }
	.d-print-inline-block {
		display: inline-block !important; }
	.d-print-block {
		display: block !important; }
	.d-print-table {
		display: table !important; }
	.d-print-table-row {
		display: table-row !important; }
	.d-print-table-cell {
		display: table-cell !important; }
	.d-print-flex {
		display: flex !important; }
	.d-print-inline-flex {
		display: inline-flex !important; } }

.embed-responsive {
	position: relative;
	display: block;
	width: 100%;
	padding: 0;
	overflow: hidden; }
	.embed-responsive::before {
		display: block;
		content: ""; }
	.embed-responsive .embed-responsive-item,
	.embed-responsive iframe,
	.embed-responsive embed,
	.embed-responsive object,
	.embed-responsive video {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: 0; }

.embed-responsive-21by9::before {
	padding-top: 42.85714%; }

.embed-responsive-16by9::before {
	padding-top: 56.25%; }

.embed-responsive-4by3::before {
	padding-top: 75%; }

.embed-responsive-1by1::before {
	padding-top: 100%; }

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
	position: static;
	width: auto;
	height: auto;
	overflow: visible;
	clip: auto;
	white-space: normal; }

/*-------------------------------------------------------*/
/* General
/*-------------------------------------------------------*/
html {
	font-size: 100%; }

.clearfix {
	*zoom: 1; }
	.clearfix:before, .clearfix:after {
		display: table;
		line-height: 0;
		content: ""; }
	.clearfix:after {
		clear: both; }

::-moz-selection {
	color: #fff;
	background-color: #212121; }

::-webkit-selection {
	color: #fff;
	background-color: #212121; }

::selection {
	color: #fff;
	background-color: #212121; }

:focus {
	outline: none; }

body {
	margin: 0;
	padding: 0;
	font-family: "nimbus-sans", "Archivo", sans-serif;
	font-size: 22px;
	font-size: 1.375rem;
	line-height: 1.5;
	font-smoothing: antialiased;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	background: #fff;
	outline: 0;
	overflow-x: hidden;
	overflow-y: auto;
	color: #262626; }

img {
	border: none;
	height: auto;
	max-width: 100%;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none; }

a {
	text-decoration: none;
	color: #ffc62d;
	transition: color 0.1s ease-in-out; }
	@media (prefers-reduced-motion: reduce) {
		a {
			transition: none; } }
	a:hover, a:focus {
		text-decoration: none;
		color: #000000; }

p {
	margin: 0 0 2rem; }

video {
	height: 100%;
	width: 100%; }

hr {
	margin: 0;
	border-color: #E9E9E9; }

address {
	font-style: normal;
	margin-bottom: 0; }

/*-------------------------------------------------------*/
/* Typography
/*-------------------------------------------------------*/
ul, ol {
	margin: 0;
	padding: 0; }

h1, h2, h3, h4, h5, h6 {
	font-family: "aktiv-grotesk-extended", "Montserrat", sans-serif;
	margin-top: 0;
	margin-bottom: 10px;
	color: #000000;
	font-weight: 700;
	line-height: 1.3;
	letter-spacing: -0.05em; }

h1 {
	font-size: 3.5rem; }

h2 {
	font-size: 3rem; }

h3 {
	font-size: 1.75rem; }

h4 {
	font-size: 1.5rem; }

h5 {
	font-size: 1.375rem; }

h6 {
	font-size: 1.25rem; }

@media only screen and (max-width: 1024px) {
	h1 {
		font-size: 2.8rem; }
	h2 {
		font-size: 2.5rem; }
	h3 {
		font-size: 1.625rem; } }

h1 > a, h2 > a, h3 > a, h4 > a, h5 > a, h6 > a {
	color: inherit; }
	h1 > a:hover, h1 > a:focus, h2 > a:hover, h2 > a:focus, h3 > a:hover, h3 > a:focus, h4 > a:hover, h4 > a:focus, h5 > a:hover, h5 > a:focus, h6 > a:hover, h6 > a:focus {
		color: inherit; }

h1 small, h2 small, h3 small, h4 small, h5 small, h6 small {
	color: #000000;
	font-size: 85%; }

.text-center {
	text-align: center; }

.text-right {
	text-align: right; }

.text-left {
	text-align: left; }

@media only screen and (max-width: 1439px) {
	.text-lg-center {
		text-align: center; } }

@media only screen and (max-width: 1024px) {
	.text-md-center {
		text-align: center; } }

@media only screen and (max-width: 767px) {
	.text-sm-center {
		text-align: center; } }

@media only screen and (max-width: 575px) {
	.text-xs-center {
		text-align: center; } }

@media only screen and (max-width: 1439px) {
	.text-lg-left {
		text-align: left; } }

@media only screen and (max-width: 1024px) {
	.text-md-left {
		text-align: left; } }

@media only screen and (max-width: 767px) {
	.text-sm-left {
		text-align: left; } }

@media only screen and (max-width: 575px) {
	.text-xs-left {
		text-align: left; } }

@media only screen and (max-width: 1439px) {
	.text-lg-right {
		text-align: right; } }

@media only screen and (max-width: 1024px) {
	.text-md-right {
		text-align: right; } }

@media only screen and (max-width: 767px) {
	.text-sm-right {
		text-align: right; } }

@media only screen and (max-width: 575px) {
	.text-xs-right {
		text-align: right; } }

.title-row {
	margin-bottom: 72px;
	position: relative; }
	@media only screen and (max-width: 1024px) {
		.title-row {
			margin-bottom: 48px; } }
	.title-row--boxed {
		max-width: 560px;
		margin-left: auto;
		margin-right: auto; }

.section-title {
	margin-bottom: 34px; }

.highlight {
	color: #ffc62d; }

.text-large {
	font-size: 32px;
	font-size: 2rem;
	line-height: 1.4; }
	@media only screen and (min-width: 1025px) {
		.text-large {
			font-size: 40px;
			font-size: 2.5rem; } }

.lead {
	font-size: 1.25rem;
	line-height: 1.666666; }

figcaption {
	font-size: 0.9375rem;
	font-style: italic;
	margin-top: 16px;
	color: #909090;
	text-align: center; }

@font-face {
	font-family: 'dorya';
	src: url("../fonts/dorya.ttf?6lrnp2") format("truetype"), url("../fonts/dorya.woff?6lrnp2") format("woff"), url("../fonts/dorya.svg?6lrnp2#dorya") format("svg");
	font-weight: normal;
	font-style: normal;
	font-display: block; }

[class^="dorya-icon-"], [class*=" dorya-icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'dorya' !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale; }

.dorya-icon-deviantart:before {
	content: "\e902"; }

.dorya-icon-slack:before {
	content: "\e903"; }

.dorya-icon-telegram:before {
	content: "\e904"; }

.dorya-icon-instagram:before {
	content: "\e905"; }

.dorya-icon-dribbble:before {
	content: "\e906"; }

.dorya-icon-linkedin:before {
	content: "\e907"; }

.dorya-icon-pinterest:before {
	content: "\e908"; }

.dorya-icon-twitter:before {
	content: "\e909"; }

.dorya-icon-vimeo:before {
	content: "\e90a"; }

.dorya-icon-youtube:before {
	content: "\e90b"; }

.dorya-icon-github:before {
	content: "\e90c"; }

.dorya-icon-facebook:before {
	content: "\e90d"; }

.dorya-icon-bitbucket:before {
	content: "\e90e"; }

.dorya-icon-behance:before {
	content: "\e90f"; }

.dorya-icon-chevron-down:before {
	content: "\e910"; }

.dorya-icon-chevron-left:before {
	content: "\e911"; }

.dorya-icon-chevron-right:before {
	content: "\e912"; }

.dorya-icon-chevron-up:before {
	content: "\e913"; }

.dorya-icon-arrow-diagonal-up:before {
	content: "\e914"; }

.dorya-icon-arrow-left:before {
	content: "\e900"; }

.dorya-icon-arrow-right:before {
	content: "\e901"; }

/*-------------------------------------------------------*/
/* Grid
/*-------------------------------------------------------*/
.main-wrap {
	overflow: hidden;
	position: relative; }

.content-wrap {
	position: relative;
	z-index: 2; }

section {
	background-color: #fff;
	padding: 88px 0;
	position: relative;
	background-size: cover;
	background-position: center; }
	@media only screen and (max-width: 1024px) {
		section {
			padding: 60px 0; } }

.section-large-padding {
	padding: 80px 0; }
	@media only screen and (min-width: 1025px) {
		.section-large-padding {
			padding: 140px 0; } }

@media only screen and (max-width: 1024px) {
	.container, .container-fluid, .container-lg, .container-xl,
	.container-semi-fluid {
		padding: 0 30px; } }

.container-semi-fluid {
	padding: 0 50px; }

.main-container {
	margin: auto; }

@media (min-width: 1800px) {
	.container {
		max-width: 1680px; } }

/* Columns Gutters
-------------------------------------------------------*/
.row-10 {
	margin: 0 -5px; }

.row-10 > [class*="col-"] {
	padding: 0 5px; }

.row-14 {
	margin: 0 -7px; }

.row-14 > [class*="col-"] {
	padding: 0 7px; }

.row-50 {
	margin: 0 -25px; }

.row-50 > [class*="col-"] {
	padding: 0 25px; }

/* Flexbox
-------------------------------------------------------*/
.flex-parent {
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-flow: row nowrap;
	-ms-flex-flow: row nowrap;
	flex-flow: row nowrap;
	justify-content: space-between; }

.flex-child {
	-webkit-box-flex: 1 0 0;
	-webkit-flex: 1 0 0;
	-ms-flex: 1 0 0;
	flex: 1 0 0; }

/* Flexbox Align
-------------------------------------------------------*/
.justify-content-center {
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center; }

.align-items-center {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center; }

/*-------------------------------------------------------*/
/* Navigation
/*-------------------------------------------------------*/
.nav {
	min-height: 60px;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	z-index: 120;
	position: relative;
	background-color: #fff;
	transition: height 0.3s ease;
	/* Dropdowns (large screen) */ }
	@media (prefers-reduced-motion: reduce) {
		.nav {
			transition: none; } }
	@media only screen and (min-width: 1025px) {
		.nav {
			min-height: 136px; } }
	.nav__header {
		position: relative; }
	.nav__menu, .nav__dropdown-menu {
		list-style: none; }
	.nav__menu {
		position: relative;
		display: -webkit-inline-box;
		display: -ms-inline-flexbox;
		display: -webkit-inline-flex;
		display: inline-flex;
		align-items: center;
		pointer-events: none; }
		.nav__menu > * {
			pointer-events: auto; }
		.nav__menu > li {
			position: relative;
			padding: 0 24px; }
		.nav__menu > li > a {
			font-family: "aktiv-grotesk-extended", "Montserrat", sans-serif;
			font-weight: 700;
			color: #000000;
			font-size: 18px;
			font-size: 1.125rem;
			line-height: 60px;
			display: block;
			position: relative;
			transition: line-height 0.3s ease, color 0.1s ease; }
			@media (prefers-reduced-motion: reduce) {
				.nav__menu > li > a {
					transition: none; } }
		.nav__menu > li > a::after {
			content: '';
			position: absolute;
			display: block;
			z-index: -1;
			top: 50%;
			-webkit-transform: translateY(-50%);
			-moz-transform: translateY(-50%);
			-ms-transform: translateY(-50%);
			-o-transform: translateY(-50%);
			transform: translateY(-50%);
			height: 4px;
			width: 0;
			background-color: #ffc62d;
			transition: all 0.4s cubic-bezier(0.25, 0.74, 0.22, 0.99); }
			@media (prefers-reduced-motion: reduce) {
				.nav__menu > li > a::after {
					transition: none; } }
		.nav__menu > li > .active::after,
		.nav__menu > li > a:hover::after {
			width: 100%; }
		.nav__menu > li:last-child {
			padding-right: 0; }
	@media only screen and (min-width: 1025px) {
		.nav__menu:hover > li > a {
			color: #b5b5b5; }
			.nav__menu:hover > li > a::after {
				width: 0; }
		.nav__menu:hover > li > a:hover {
			color: #000000; }
			.nav__menu:hover > li > a:hover::after {
				width: 100%; }
		.nav__wrap {
			display: block !important;
			height: auto !important; }
		.nav__wrap--text-center {
			text-align: center; }
		.nav__menu > li {
			display: inline-block;
			text-align: center; }
		.nav__dropdown-menu {
			position: absolute;
			top: 100%;
			left: 0;
			z-index: 1000;
			min-width: 240px;
			width: 100%;
			text-align: left;
			padding: 16px 0;
			list-style: none;
			white-space: nowrap;
			background-color: #131313;
			box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
			-webkit-background-clip: padding-box;
			background-clip: padding-box;
			display: block;
			visibility: hidden;
			opacity: 0;
			transition: all 0.1s ease-in-out; } }
	@media only screen and (min-width: 1025px) and (prefers-reduced-motion: reduce) {
		.nav__dropdown-menu {
			transition: none; } }
	@media only screen and (min-width: 1025px) {
			.nav__dropdown-menu > li > a {
				color: #fff;
				padding: 6px 30px;
				font-size: 16px;
				font-size: 1rem;
				display: block;
				position: relative;
				transition: all 0.4s cubic-bezier(0.25, 0.74, 0.22, 0.99); } }
		@media only screen and (min-width: 1025px) and (prefers-reduced-motion: reduce) {
			.nav__dropdown-menu > li > a {
				transition: none; } }
	@media only screen and (min-width: 1025px) {
				.nav__dropdown-menu > li > a:hover, .nav__dropdown-menu > li > a:focus {
					color: #fff; }
			.nav__dropdown-menu-label {
				position: relative; }
				.nav__dropdown-menu-label:after {
					content: "";
					display: block;
					position: absolute;
					border-bottom: 1px solid #fff;
					width: 0;
					transition: width 0.3s linear; } }
			@media only screen and (min-width: 1025px) and (prefers-reduced-motion: reduce) {
				.nav__dropdown-menu-label:after {
					transition: none; } }
	@media only screen and (min-width: 1025px) {
					.mobile .nav__dropdown-menu-label:after {
						display: none; }
			.nav__dropdown-menu > li > a:hover .nav__dropdown-menu-label:after {
				width: 100%; }
		.nav__dropdown-menu.hide-dropdown {
			visibility: hidden !important;
			opacity: 0 !important; }
		.nav__dropdown-menu--right {
			right: 0; }
		.nav__dropdown:hover > .nav__dropdown-menu {
			opacity: 1;
			visibility: visible; }
		.nav__dropdown-trigger {
			display: none; } }

.mobile body {
	cursor: pointer; }

/* Logo
-------------------------------------------------------*/
.logo {
	max-height: 39px;
	position: relative; }

.logo__url {
	height: 136px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	align-items: center;
	transition: all 0.3s ease; }
	@media (prefers-reduced-motion: reduce) {
		.logo__url {
			transition: none; } }

.logo--left {
	justify-content: flex-start; }

/* Nav Flexbox
-------------------------------------------------------*/
header .flex-parent {
	align-items: center; }
	@media only screen and (max-width: 1024px) {
		header .flex-parent {
			display: block; } }

header .flex-child {
	flex: 1 0 0; }
	@media only screen and (max-width: 1024px) {
		header .flex-child {
			width: 100%; } }

header .flex-child.flex-right {
	text-align: right; }

.nav--align-right {
	margin-left: auto; }

.nav--align-left {
	margin-right: auto; }
	@media only screen and (min-width: 1025px) {
		.nav--align-left {
			margin-left: 60px; } }

@media only screen and (min-width: 1025px) {
	.nav--align-center .nav__header {
		flex: 1 0 0; }
	.nav--align-center .nav__wrap {
		flex: 4 0 0;
		text-align: center; } }

/* Nav Icon Toggle (mobile nav)
-------------------------------------------------------*/
.nav__icon-toggle {
	position: absolute;
	top: 50%;
	right: -10px;
	width: 40px;
	height: 32px;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
	backface-visibility: hidden;
	padding: 10px;
	background-color: transparent;
	border: none;
	z-index: 50; }
	@media only screen and (min-width: 1025px) {
		.nav__icon-toggle {
			display: none; } }
	.nav__icon-toggle span:nth-child(2) {
		top: 9px;
		transition: top 0.2s 0.2s ease-in, opacity 0.1s ease-in; }
		@media (prefers-reduced-motion: reduce) {
			.nav__icon-toggle span:nth-child(2) {
				transition: none; } }
	.nav__icon-toggle span:nth-child(3) {
		top: 15px;
		transition: opacity 0.1s ease-in; }
		@media (prefers-reduced-motion: reduce) {
			.nav__icon-toggle span:nth-child(3) {
				transition: none; } }
	.nav__icon-toggle span:nth-child(4) {
		bottom: 10px;
		transition: bottom 0.2s 0.2s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
		@media (prefers-reduced-motion: reduce) {
			.nav__icon-toggle span:nth-child(4) {
				transition: none; } }
	.nav__icon-toggle--is-opened span:nth-child(2) {
		top: 15px;
		transition: 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
		-webkit-transform: rotate(45deg);
		-moz-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		-o-transform: rotate(45deg);
		transform: rotate(45deg); }
		@media (prefers-reduced-motion: reduce) {
			.nav__icon-toggle--is-opened span:nth-child(2) {
				transition: none; } }
	.nav__icon-toggle--is-opened span:nth-child(3) {
		opacity: 0; }
	.nav__icon-toggle--is-opened span:nth-child(4) {
		bottom: 16px;
		-webkit-transform: rotate(-45deg);
		-moz-transform: rotate(-45deg);
		-ms-transform: rotate(-45deg);
		-o-transform: rotate(-45deg);
		transform: rotate(-45deg);
		transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }
		@media (prefers-reduced-motion: reduce) {
			.nav__icon-toggle--is-opened span:nth-child(4) {
				transition: none; } }
	.nav__icon-toggle-bar {
		background-color: #131313;
		width: 20px;
		position: absolute;
		display: block;
		height: 1px; }
		.nav__icon-toggle-bar:last-child {
			margin-bottom: 0; }
	.nav__icon-toggle:focus, .nav__icon-toggle:hover {
		background-color: transparent;
		outline: 0; }

/* Sticky Nav
-------------------------------------------------------*/
.nav--sticky {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	transition: all .3s ease; }
	@media only screen and (min-width: 1025px) {
		.nav--sticky {
			height: 136px; }
			.nav--sticky--is-scrolling {
				height: 60px; } }
	.nav--sticky--is-sticky {
		transform: translateY(-100%); }
	.nav--sticky--is-scrolling {
		box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
		background-color: #fff;
		z-index: 120; }
		.nav--sticky--is-scrolling .logo-wrap a {
			height: 60px; }

.header--always-fixed {
	position: fixed;
	background-color: transparent;
	top: 0;
	right: 0;
	left: 0; }
	@media only screen and (min-width: 1025px) {
		.header--always-fixed {
			min-height: 0; } }

/* Transparent Nav
-------------------------------------------------------*/
@media only screen and (max-width: 1024px) {
	.nav--transparent .logo--white {
		display: none; } }

@media only screen and (min-width: 1025px) {
	.nav--transparent:not(.nav--sticky--is-scrolling) .nav__menu > li > a {
		color: #fff; }
	.nav--transparent:not(.nav--sticky--is-scrolling) .logo--dark,
	.nav--transparent.nav--sticky--is-scrolling .logo--white {
		display: none; } }

/*-------------------------------------------------------*/
/* Nav Mobile Styles
/*-------------------------------------------------------*/
@media only screen and (max-width: 1024px) {
	.nav--sticky {
		background-color: #fff; }
	.nav__menu {
		display: block; }
	.nav__menu li {
		padding: 0;
		border-bottom: 1px solid #E9E9E9; }
	.nav__menu li a {
		padding: 0;
		line-height: 50px;
		height: 50px;
		display: inline-block; }
	.nav__dropdown-menu a {
		color: #262626; }
		.nav__dropdown-menu a:hover {
			color: #0052f8; }
	.nav__dropdown-menu > li > a {
		padding-left: 10px; }
	.nav__dropdown-trigger {
		display: block;
		width: 20px;
		height: 50px;
		line-height: 50px;
		font-size: 0.75rem;
		text-align: center;
		position: absolute;
		right: 0;
		top: 0;
		z-index: 50;
		cursor: pointer;
		transition: all 0.4s cubic-bezier(0.25, 0.74, 0.22, 0.99); } }
	@media only screen and (max-width: 1024px) and (prefers-reduced-motion: reduce) {
		.nav__dropdown-trigger {
			transition: none; } }

@media only screen and (max-width: 1024px) {
		.nav__dropdown-trigger--is-open {
			-webkit-transform: rotate(180deg);
			-moz-transform: rotate(180deg);
			-ms-transform: rotate(180deg);
			-o-transform: rotate(180deg);
			transform: rotate(180deg); }
	.nav__dropdown-menu {
		display: none;
		width: 100% !important; }
	.nav__dropdown-trigger.active + .nav__dropdown-menu {
		display: block; }
	.logo-wrap {
		float: left; }
		.logo-wrap a {
			display: table-cell;
			vertical-align: middle;
			height: 60px; } }

/*-------------------------------------------------------*/
/* Footer
/*-------------------------------------------------------*/
.footer {
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	background: url("../img/footer/footer_background.jpg"); }
	@media only screen and (min-width: 1025px) {
		.footer {
			position: fixed;
			bottom: 0;
			left: 0;
			width: 100%;
			z-index: 1; } }
	.footer a:not(.widget-cta__link) {
		color: #fff; }
		.footer a:not(.widget-cta__link):hover, .footer a:not(.widget-cta__link):focus {
			color: #ffc62d; }

/* Footer CTA
-------------------------------------------------------*/
.footer__cta {
	padding-top: 180px; }

/* Footer Widgets
-------------------------------------------------------*/
.footer__widgets {
	padding: 90px 0 48px;
	border-bottom: 1px solid rgba(255, 255, 255, 0.2); }

/* Bottom Footer
-------------------------------------------------------*/
.footer__bottom {
	padding: 28px 0; }
	.footer__bottom .row {
		align-items: center; }
	.footer__bottom .widget-nav-menu {
		margin-bottom: 0; }
		@media only screen and (min-width: 768px) {
			.footer__bottom .widget-nav-menu {
				text-align: right; } }
		.footer__bottom .widget-nav-menu li {
			display: inline-block;
			margin-right: 30px; }
			.footer__bottom .widget-nav-menu li a {
				font-size: 16px;
				font-size: 1rem; }
			.footer__bottom .widget-nav-menu li:last-child {
				margin-right: 0; }

/*-------------------------------------------------------*/
/* Preloader
/*-------------------------------------------------------*/
.loader-mask {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	height: 100vh;
	background-color: #000000;
	z-index: 99999; }

.loader {
	display: block;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	font-size: 0; }
	.loader-logo {
		margin-bottom: 12px; }

.loader-bar {
	border-radius: 5px;
	height: 3px;
	background-color: rgba(255, 255, 255, 0.3); }
	.loader-bar span {
		content: '';
		display: block;
		border-radius: 5px;
		height: 3px;
		width: 0;
		background-color: #fff;
		transition: all 1.2s cubic-bezier(0.25, 0.74, 0.22, 0.99); }

@keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg); }
	100% {
		-webkit-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg); } }

@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg); }
	100% {
		-webkit-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg); } }

@keyframes spin-reverse {
	0% {
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg); }
	100% {
		-webkit-transform: rotate(-360deg);
		-moz-transform: rotate(-360deg);
		-ms-transform: rotate(-360deg);
		-o-transform: rotate(-360deg);
		transform: rotate(-360deg); } }

@-webkit-keyframes spin-reverse {
	0% {
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg); }
	100% {
		-webkit-transform: rotate(-360deg);
		-moz-transform: rotate(-360deg);
		-ms-transform: rotate(-360deg);
		-o-transform: rotate(-360deg);
		transform: rotate(-360deg); } }

/*-------------------------------------------------------*/
/* Buttons
/*-------------------------------------------------------*/
.btn {
	font-weight: 700;
	font-family: "aktiv-grotesk-extended", "Montserrat", sans-serif;
	overflow: hidden;
	display: inline-block;
	text-decoration: none;
	text-align: center;
	height: auto;
	width: auto;
	border: 0;
	border-radius: 0;
	transition: all 0.4s cubic-bezier(0.25, 0.74, 0.22, 0.99);
	font-size: 18px;
	font-size: 1.125rem;
	color: #fff;
	background-color: #131313;
	backface-visibility: hidden;
	position: relative;
	z-index: 1;
	white-space: nowrap;
	vertical-align: middle;
	touch-action: manipulation;
	cursor: pointer;
	user-select: none; }
	@media (prefers-reduced-motion: reduce) {
		.btn {
			transition: none; } }
	.btn:hover {
		color: #fff;
		background-color: #0052f8;
		box-shadow: 0 12px 20px rgba(0, 82, 248, 0.25);
		border-color: transparent; }
	.btn:focus {
		outline: none;
		color: #fff; }
	.btn--lg {
		padding: 29px 64px; }
	.btn--md {
		font-size: 18px;
		font-size: 1.125rem;
		padding: 24px 48px; }
	.btn--color {
		background-color: #0052f8; }
	.btn--wide {
		width: 100%; }

.rounded,
.rounded:before {
	border-radius: 70px; }

/*-------------------------------------------------------*/
/* Form Elements
/*-------------------------------------------------------*/
input,
select,
textarea {
	height: 56px;
	border-radius: 0;
	border: 1px solid #afafaf;
	width: 100%;
	margin-bottom: 16px;
	padding: 0 12px;
	font-size: 18px;
	font-size: 1.125rem;
	transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out; }
	@media (prefers-reduced-motion: reduce) {
		input,
		select,
		textarea {
			transition: none; } }

textarea {
	height: auto;
	display: block;
	padding: 8px 16px; }

input:focus,
textarea:focus {
	border-color: #0052f8;
	background-color: #fff;
	outline: none;
	box-shadow: none; }

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
	-webkit-appearance: button;
	cursor: pointer;
	padding: 24px 48px; }
	button:focus,
	input[type="button"]:focus,
	input[type="reset"]:focus,
	input[type="submit"]:focus {
		background-color: #0052f8; }

/* Change Color of Placeholders */
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
	color: #262626; }

input:-moz-placeholder,
textarea:-moz-placeholder {
	color: #262626;
	opacity: 1; }

input::-moz-placeholder,
textarea::-moz-placeholder {
	color: #262626;
	opacity: 1; }

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
	color: #262626; }

label {
	color: #000000;
	font-family: "aktiv-grotesk-extended", "Montserrat", sans-serif;
	font-size: 1rem;
	display: inline-block;
	vertical-align: middle;
	margin-bottom: 10px;
	line-height: 1.5; }

.form-group {
	position: relative; }

/* Checkboxes & Radio Buttons
-------------------------------------------------------*/
input[type="checkbox"],
input[type="radio"] {
	min-width: 20px;
	width: 20px;
	height: 20px;
	margin-bottom: 0;
	vertical-align: middle;
	margin-right: 10px;
	text-align: center;
	line-height: 18px;
	border-radius: 0;
	border: 1px solid #d0d0d0;
	background-color: #fff; }
	input[type="checkbox"]:hover,
	input[type="radio"]:hover {
		border-color: #b6b6b6;
		background-color: #fff; }

input[type="checkbox"] + label,
input[type="radio"] + label {
	cursor: pointer;
	margin-bottom: 0;
	position: relative;
	line-height: 22px; }

input[type="radio"] {
	border-radius: 50%; }
	input[type="radio"]:checked:after {
		content: '';
		width: 8px;
		height: 8px;
		position: relative;
		top: -2px;
		border-radius: 50%;
		display: inline-block;
		background-color: #000000; }

.radio,
.checkbox {
	list-style: none; }

.consent-checkbox {
	margin-top: 18px;
	margin-bottom: 40px;
	overflow: hidden;
	display: flex; }
	.consent-checkbox__label {
		line-height: 20px;
		font-size: 1rem;
		color: #262626;
		font-family: "nimbus-sans", "Archivo", sans-serif; }
	.consent-checkbox input[type=checkbox] {
		float: left; }

/* GDPR Checkbox
-------------------------------------------------------*/
.gdpr-checkbox {
	margin-bottom: 24px; }
	.gdpr-checkbox__label {
		display: -webkit-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		color: #262626;
		font-family: "nimbus-sans", "Archivo", sans-serif;
		line-height: 20px;
		font-size: 1rem;
		font-weight: 400;
		text-align: left;
		margin-bottom: 0; }

/*-------------------------------------------------------*/
/* Sliders
/*-------------------------------------------------------*/
.slick-slider {
	position: relative;
	display: block;
	box-sizing: border-box;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-touch-callout: none;
	-khtml-user-select: none;
	-ms-touch-action: pan-y;
	touch-action: pan-y;
	-webkit-tap-highlight-color: transparent; }

.slick-list {
	position: relative;
	display: block;
	overflow: hidden;
	margin: 0;
	padding: 0; }

.slick-list:focus {
	outline: 0; }

.slick-list.dragging {
	cursor: pointer;
	cursor: hand; }

.slick-slider .slick-list, .slick-slider .slick-track {
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	-o-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0); }

.slick-track {
	position: relative;
	top: 0;
	left: 0;
	display: block;
	margin-left: auto;
	margin-right: auto; }

.slick-track:after, .slick-track:before {
	display: table;
	content: ''; }

.slick-track:after {
	clear: both; }

.slick-loading .slick-track {
	visibility: hidden; }

.slick-slide {
	display: none;
	float: left;
	height: 100%;
	min-height: 1px; }

[dir=rtl] .slick-slide {
	float: right; }

.slick-slide img {
	display: block; }

.slick-slide.slick-loading img {
	display: none; }

.slick-slide.dragging img {
	pointer-events: none; }

.slick-initialized .slick-slide {
	display: block; }

.slick-loading .slick-slide {
	visibility: hidden; }

.slick-vertical .slick-slide {
	display: block;
	height: auto;
	border: 1px solid transparent; }

.slick-arrow.slick-hidden {
	display: none; }

.slick-dots {
	width: 100%;
	margin: 0;
	list-style: none;
	text-align: center; }
	.slick-dots li {
		position: relative;
		display: inline-block;
		cursor: pointer;
		margin: 0 7px;
		padding: 0;
		transition: all 0.4s cubic-bezier(0.25, 0.74, 0.22, 0.99); }
		@media (prefers-reduced-motion: reduce) {
			.slick-dots li {
				transition: none; } }
		.slick-dots li button {
			width: 14px;
			height: 14px;
			border-radius: 50%;
			font-size: 0;
			line-height: 0;
			padding: 0;
			outline: 0;
			border: 0;
			display: block;
			cursor: pointer;
			color: transparent;
			background-color: #E9E9E9; }
			.slick-dots li button:focus, .slick-dots li button:hover {
				outline: 0;
				background-color: #ffc62d;
				border-color: transparent; }
		.slick-dots li.slick-active button,
		.slick-dots li.slick-active button:hover {
			background-color: #ffc62d;
			border-color: transparent; }
	.slick-dots > li {
		display: inline-block; }
		.slick-dots > li:only-child {
			display: none; }

.slick-slider .slick-prev {
	left: 0; }

.slick-slider .slick-next {
	right: 0; }

.slick-slider .slick-next,
.slick-slider .slick-prev {
	font-size: 0;
	line-height: 0;
	position: absolute;
	z-index: 1;
	top: 50%;
	display: block;
	padding: 0;
	transform: translateY(-50%);
	cursor: pointer;
	color: transparent;
	border: none;
	outline: none;
	background: transparent; }
	.slick-slider .slick-next::before,
	.slick-slider .slick-prev::before {
		font-family: dorya;
		font-size: 35px;
		line-height: 1;
		color: #fff;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale; }

.slick-slider .slick-prev::before {
	content: "\e900"; }

.slick-slider .slick-next::before {
	content: "\e901"; }

.slick-slider-item {
	padding: 0 20px; }

/*-------------------------------------------------------*/
/* Social Icons
/*-------------------------------------------------------*/
.social {
	display: inline-block;
	border-radius: 50%;
	text-align: center;
	margin-right: 20px;
	margin-bottom: 6px;
	font-size: 17px;
	font-size: 1.0625rem;
	color: #999999;
	transition: all 0.1s ease-in-out; }
	@media (prefers-reduced-motion: reduce) {
		.social {
			transition: none; } }
	.social:hover, .social:focus {
		color: #000000; }
	.social:last-child {
		margin-right: 0; }

/*-------------------------------------------------------*/
/* Tables
/*-------------------------------------------------------*/
.table-responsive {
	display: block;
	width: 100%;
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
	-ms-overflow-style: -ms-autohiding-scrollbar; }

table th {
	font-weight: normal; }

table caption {
	margin-bottom: 10px; }

table > thead > tr > th,
table > tbody > tr > th,
table > tfoot > tr > th,
table > thead > tr > td,
table > tbody > tr > td,
table > tfoot > tr > td {
	border: 1px solid #E9E9E9;
	padding: 10px;
	letter-spacing: -0.05em; }

table > thead > tr > th {
	border-bottom: none; }

table thead tr th {
	font-size: 12px;
	color: #000000;
	font-family: "aktiv-grotesk-extended", "Montserrat", sans-serif;
	text-transform: uppercase;
	font-weight: bold; }

.wp-block-table {
	overflow-x: auto; }

.wp-block-table.is-style-stripes td {
	border-color: transparent; }

.wp-block-table.is-style-stripes tr:nth-child(odd) {
	background-color: #F6F6F6; }

/*-------------------------------------------------------*/
/* Intro
/*-------------------------------------------------------*/
@media only screen and (min-width: 768px) {
	.intro {
		padding-bottom: 172px; } }

.intro__text {
	max-width: 1120px;
	margin-left: auto;
	margin-bottom: 0; }

.intro__subtitle {
	text-transform: uppercase;
	font-size: 24px;
	font-size: 1.5rem;
	font-weight: 400;
	color: #9c9c9c;
	max-width: 200px;
	margin-bottom: 120px; }

/*-------------------------------------------------------*/
/* Aniamate Letters
/*-------------------------------------------------------*/
.animate-letters span {
	display: inline-block; }

/*-------------------------------------------------------*/
/* Play Button
/*-------------------------------------------------------*/
.play-btn {
	display: inline-block;
	position: absolute;
	z-index: 3;
	width: 72px;
	height: 72px;
	border: 3px solid #fff;
	border-radius: 50%;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out; }
	.play-btn:before {
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		display: block;
		margin: -10px 0 0 -6px;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 10px 0 10px 16px;
		border-color: transparent transparent transparent #fff; }

/* Icon Wave */
.icon-wave {
	-webkit-transition: -webkit-transform ease-out 0.1s, background 0.2s;
	-moz-transition: -moz-transform ease-out 0.1s, background 0.2s;
	transition: transform ease-out 0.1s, background 0.2s; }

.icon-wave:after {
	pointer-events: none;
	position: absolute;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	content: '';
	-webkit-box-sizing: content-box;
	-moz-box-sizing: content-box;
	box-sizing: content-box;
	top: 0;
	left: 0;
	padding: 0;
	z-index: -1;
	box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1);
	opacity: 0;
	-webkit-transform: scale(0.9);
	-moz-transform: scale(0.9);
	-ms-transform: scale(0.9);
	transform: scale(0.9); }

.icon-wave:hover {
	background-color: #fff; }
	.icon-wave:hover:before {
		border-color: transparent transparent transparent #131313; }

.icon-wave:hover:after {
	-webkit-animation: sonarEffect 1s ease-out 75ms;
	-moz-animation: sonarEffect 1s ease-out 75ms;
	animation: sonarEffect 1s ease-out 75ms; }

@-webkit-keyframes sonarEffect {
	0% {
		opacity: 0.3; }
	40% {
		opacity: 0.5;
		box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px #fff, 0 0 0 10px rgba(255, 255, 255, 0.5); }
	100% {
		box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px #fff, 0 0 0 10px rgba(255, 255, 255, 0.5);
		-webkit-transform: scale(1.3);
		opacity: 0; } }

@-moz-keyframes sonarEffect {
	0% {
		opacity: 0.3; }
	40% {
		opacity: 0.5;
		box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px #fff, 0 0 0 10px rgba(255, 255, 255, 0.5); }
	100% {
		box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px #fff, 0 0 0 10px rgba(255, 255, 255, 0.5);
		-moz-transform: scale(1.3);
		opacity: 0; } }

@keyframes sonarEffect {
	0% {
		opacity: 0.3; }
	40% {
		opacity: 0.5;
		box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px #fff, 0 0 0 10px rgba(255, 255, 255, 0.5); }
	100% {
		box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px #fff, 0 0 0 10px rgba(255, 255, 255, 0.5);
		transform: scale(1.3);
		opacity: 0; } }

/*-------------------------------------------------------*/
/* Alerts
/*-------------------------------------------------------*/
.alert-dismissible .close {
	padding: 0.70rem 1.25rem;
	height: 100%; }

/*-------------------------------------------------------*/
/* Hero
/*-------------------------------------------------------*/
.hero {
	padding-bottom: 60px;
	padding-top: 60px;
	padding: 0; }
	@media only screen and (min-width: 1025px) {
		.hero {
			height: calc( 100vh - 136px); } }
	.hero__container {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-around; }
		@media only screen and (min-width: 1440px) {
			.hero__container {
				justify-content: space-between; } }
	.hero__text-content-wrap {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: center;
		height: 100%;
		text-align: center; }
		@media only screen and (min-width: 768px) {
			.hero__text-content-wrap {
				text-align: right; } }
		@media only screen and (max-width: 767px) {
			.hero__text-content-wrap {
				padding: 60px 0; } }
	.hero__text-content {
		width: 100%;
		margin-top: -30px; }
		@media only screen and (min-width: 768px) {
			.hero__text-content {
				margin-top: -60px; } }
	.hero__img {
		position: relative;
		padding-left: 30px; }
		@media only screen and (min-width: 1025px) {
			.hero__img {
				padding-left: 120px; } }
	.hero__img-cover {
		background-color: #ffffff;
		position: absolute;
		z-index: 1;
		top: 0;
		height: 100%;
		width: 100%;
		left: 0;
		transform-origin: 0 0; }
	.hero__img-svg-container {
		position: relative;
		display: inline-block;
		width: 46%;
		padding-bottom: 100%;
		vertical-align: middle;
		overflow: hidden; }
	.hero__img-svg {
		display: inline-block;
		position: absolute;
		left: 0;
		top: 10px; }
	.hero__title {
		font-size: 68px;
		font-size: 4.25rem;
		margin-left: -0.07em; }
		@media only screen and (min-width: 1025px) {
			.hero__title {
				font-size: 88px;
				font-size: 5.5rem; } }
		@media only screen and (min-width: 1440px) {
			.hero__title {
				font-size: 152px;
				font-size: 9.5rem; } }
	.hero__subtitle {
		color: #b5b5b5;
		font-weight: 300;
		margin-bottom: 0;
		font-size: 30px;
		font-size: 1.875rem; }
		@media only screen and (min-width: 768px) {
			.hero__subtitle {
				font-size: 32px;
				font-size: 2rem; } }
		@media only screen and (min-width: 1025px) {
			.hero__subtitle {
				font-size: 40px;
				font-size: 2.5rem; } }
		@media only screen and (min-width: 1440px) {
			.hero__subtitle {
				font-size: 66px;
				font-size: 4.125rem; } }
	.hero__bottom-row {
		margin-bottom: 40px; }
	.hero__socials {
		text-align: center; }
		@media only screen and (min-width: 768px) {
			.hero__socials {
				text-align: right; } }

/*-------------------------------------------------------*/
/* Service Boxes
/*-------------------------------------------------------*/
.services {
	display: flex;
	flex-wrap: wrap; }

.services-list {
	list-style: none;
	position: relative;
	width: 100%; }
	@media only screen and (min-width: 1025px) {
		.services-list {
			flex: 0 0 50%;
			max-width: 50%;
			padding-right: 40px; } }
	@media only screen and (max-width: 1024px) {
		.services-list {
			margin-bottom: 56px; } }
	.services-list__item {
		position: relative;
		font-family: "aktiv-grotesk-extended", "Montserrat", sans-serif;
		font-weight: 700;
		color: #b5b5b5;
		cursor: pointer;
		letter-spacing: -0.05em;
		transition: all 0.4s cubic-bezier(0.25, 0.74, 0.22, 0.99);
		font-size: 34px;
		font-size: 2.125rem; }
		@media (prefers-reduced-motion: reduce) {
			.services-list__item {
				transition: none; } }
		@media only screen and (min-width: 1025px) {
			.services-list__item {
				font-size: 56px;
				font-size: 3.5rem; } }
		.services-list__item:hover, .services-list__item--is-active {
			color: #000000; }
			.services-list__item:hover span::after, .services-list__item--is-active span::after {
				width: 100%; }
	.services-list__label {
		position: relative;
		z-index: 1; }
		.services-list__label::after {
			content: '';
			z-index: -1;
			position: absolute;
			display: block;
			bottom: 10px;
			height: 16px;
			width: 0;
			background-color: #ffc62d;
			transition: all 0.4s cubic-bezier(0.25, 0.74, 0.22, 0.99); }
			@media only screen and (min-width: 1025px) {
				.services-list__label::after {
					bottom: 20px; } }

.services-content {
	list-style: none;
	position: relative;
	width: 100%; }
	@media only screen and (min-width: 1025px) {
		.services-content {
			flex: 0 0 50%;
			max-width: 50%; } }
	.services-content__item {
		font-size: 28px;
		font-size: 1.75rem;
		margin-bottom: 48px;
		line-height: 1.3;
		color: #b5b5b5;
		transition: all 0.4s cubic-bezier(0.25, 0.74, 0.22, 0.99); }
		@media (prefers-reduced-motion: reduce) {
			.services-content__item {
				transition: none; } }
		.services-content__item:last-child {
			margin-bottom: 0; }
		@media only screen and (min-width: 1025px) {
			.services-content__item {
				font-size: 40px;
				font-size: 2.5rem; } }
		.services-content__item--is-active {
			color: #000000; }

/*-------------------------------------------------------*/
/* Blog
/*-------------------------------------------------------*/
.entry {
	overflow: hidden;
	position: relative;
	margin-bottom: 48px; }
	.entry__header {
		margin-bottom: 36px; }
	.entry__img {
		width: 100%; }
		.entry__img-url {
			display: block; }
	.entry__url {
		position: absolute;
		display: block;
		width: 100%;
		bottom: 0;
		top: 0;
		right: 0;
		left: 0;
		z-index: 1; }
	.entry__info-holder {
		position: absolute;
		bottom: 0;
		padding: 40px;
		z-index: 1;
		pointer-events: none; }
		.entry__info-holder .entry__title {
			color: #fff;
			font-size: 1.5rem; }
		.entry__info-holder .entry__meta-date {
			color: #fff; }
	.entry__title {
		font-size: 1.75rem;
		margin-top: 16px;
		margin-bottom: 16px; }
	.entry__excerpt {
		margin-top: 20px; }
		.entry__excerpt p {
			margin-bottom: 0; }

/*-------------------------------------------------------*/
/* Meta
/*-------------------------------------------------------*/
.entry__meta {
	display: flex; }
	.entry__meta-item {
		display: inline-block;
		font-size: 1rem;
		color: #909090;
		margin-right: 12px; }
		.entry__meta-item:last-child {
			margin-right: 0; }
	.entry__meta a {
		color: #000000; }
		.entry__meta a:hover, .entry__meta a:focus {
			color: #0052f8; }

.entry__meta-author-link {
	color: #909090;
	display: flex;
	align-items: center;
	font-family: "aktiv-grotesk-extended", "Montserrat", sans-serif;
	font-weight: 700; }

.entry__meta-author-img {
	margin-right: 22px; }

.entry__meta-author-name {
	color: #000000;
	font-size: 18px;
	font-size: 1.125rem;
	display: block; }

.entry__meta-author-position {
	font-size: 14px;
	font-size: 0.875rem; }

/*-------------------------------------------------------*/
/* Blog Slider
/*-------------------------------------------------------*/
.section-from-blog {
	padding-bottom: 120px;
	padding-top: 120px; }
	@media only screen and (min-width: 1025px) {
		.section-from-blog {
			padding-bottom: 200px;
			padding-top: 170px; } }

.blog-slider .slick-prev {
	left: 40px; }

.blog-slider .slick-next {
	right: 40px; }

.blog-slider .entry,
.blog-slider .entry__img-holder {
	margin-bottom: 0; }

.blog-slider .entry__img {
	height: 480px;
	object-fit: cover; }
	@media only screen and (min-width: 1025px) {
		.blog-slider .entry__img {
			height: 696px; } }

/*-------------------------------------------------------*/
/* Projects
/*-------------------------------------------------------*/
.project {
	position: relative;
	overflow: hidden;
	padding: 30px; }
	@media only screen and (min-width: 1025px) {
		.project {
			padding: 70px; }
			.project:nth-child(2) {
				margin-top: 140px; } }
	.project-slide {
		margin: 0 19px; }
	.project__container {
		position: relative; }
	.project__img-holder {
		position: relative;
		width: 100%;
		height: 100%;
		margin: 0;
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden; }
	.project__img {
		width: 100%;
		transition: all 0.4s cubic-bezier(0.25, 0.74, 0.22, 0.99);
		overflow: hidden;
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden; }
		@media (prefers-reduced-motion: reduce) {
			.project__img {
				transition: none; } }
	.project__title, .project__category {
		opacity: 0;
		transition: opacity 0.2s cubic-bezier(0.07, 0.98, 0, 0.96); }
		@media (prefers-reduced-motion: reduce) {
			.project__title, .project__category {
				transition: none; } }
	.project__description-wrap {
		position: absolute;
		opacity: 0;
		bottom: 40px;
		left: 40px;
		display: inline-block;
		width: 78%;
		max-width: 260px;
		-webkit-clip-path: inset(0 0 0 100%);
		clip-path: inset(0 0 0 100%);
		transition: opacity 0.2s 0.3s, clip-path 0s 0.3s; }
		@media (prefers-reduced-motion: reduce) {
			.project__description-wrap {
				transition: none; } }
	.project__description {
		position: relative;
		padding: 16px 20px; }
		.project__description:after {
			content: '';
			position: absolute;
			display: block;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background-color: #fff;
			-webkit-transform-origin: 0 50%;
			-moz-transform-origin: 0 50%;
			transform-origin: 0 50%;
			-webkit-transform: scaleX(0);
			-moz-transform: scaleX(0);
			-ms-transform: scaleX(0);
			-o-transform: scaleX(0);
			transform: scaleX(0);
			transition: transform 0.35s cubic-bezier(0.07, 0.98, 0, 0.96); }
			@media (prefers-reduced-motion: reduce) {
				.project__description:after {
					transition: none; } }
	.project:hover .project__description-wrap {
		opacity: 1;
		-webkit-clip-path: inset(0 0 0 0);
		clip-path: inset(0 0 0 0);
		-webkit-transition: -webkit-clip-path 0.8s cubic-bezier(0.07, 0.98, 0, 0.96);
		transition: clip-path 0.8s cubic-bezier(0.07, 0.98, 0, 0.96); }
	.project:hover .project__description:after {
		-webkit-transform-origin: 100% 50%;
		-moz-transform-origin: 100% 50%;
		transform-origin: 100% 50%;
		-webkit-transform: scaleX(1);
		-moz-transform: scaleX(1);
		-ms-transform: scaleX(1);
		-o-transform: scaleX(1);
		transform: scaleX(1);
		transition: transform 0.7s cubic-bezier(0.07, 0.98, 0, 0.96); }
		@media (prefers-reduced-motion: reduce) {
			.project:hover .project__description:after {
				transition: none; } }
	.project:hover .project__title,
	.project:hover .project__category {
		opacity: 1; }
	.project__url {
		display: block;
		position: relative;
		width: 100%;
		height: 100%; }
	.project__title {
		font-size: 1.25rem;
		position: relative;
		z-index: 2; }
	.project__category {
		position: relative;
		z-index: 2;
		display: inline-block;
		margin-top: 16px;
		font-size: 0.875rem;
		font-weight: 700; }

/* Grid
-------------------------------------------------------*/
.project-grid {
	margin-left: -30px;
	margin-right: -30px; }
	@media only screen and (min-width: 1025px) {
		.project-grid {
			margin-left: -70px;
			margin-right: -70px; } }

.project-grid--5-col .project {
	width: 20%; }

.project-grid--4-col .project {
	width: 25%; }

.project-grid--3-col .project {
	width: 33.33333333%; }

.project-grid--2-col .project {
	width: 50%; }

@media only screen and (max-width: 767px) {
	.project-grid .project {
		width: 100%; } }

/*-------------------------------------------------------*/
/* Testimonials
/*-------------------------------------------------------*/
.testimonial {
	position: relative; }
	.testimonial__body {
		margin-bottom: 40px; }
		.testimonial__body::before {
			content: '“';
			font-family: "aktiv-grotesk-extended", "Montserrat", sans-serif;
			font-weight: 700;
			display: block;
			position: absolute;
			top: 0;
			left: -32px;
			line-height: 1.1;
			font-size: 44px;
			font-size: 2.75rem; }
			@media only screen and (max-width: 1024px) {
				.testimonial__body::before {
					position: relative;
					left: 0; } }
	.testimonial__text {
		font-size: 30px;
		font-size: 1.875rem;
		font-family: "aktiv-grotesk-extended", "Montserrat", sans-serif;
		font-weight: 700;
		line-height: 1.4; }
	.testimonial__info {
		display: flex;
		flex-wrap: nowrap;
		align-items: center; }
	.testimonial__author {
		line-height: 1.3; }
	.testimonial__img-holder {
		margin: 0; }
	.testimonial__img {
		position: relative;
		overflow: hidden;
		width: 52px;
		height: 52px;
		border-radius: 50%;
		margin-right: 24px; }
		@media only screen and (min-width: 1025px) {
			.testimonial__img {
				width: 92px;
				height: 92px; } }
	.testimonial__name {
		font-size: 18px;
		font-size: 1.125rem;
		font-family: "aktiv-grotesk-extended", "Montserrat", sans-serif;
		font-weight: 700;
		display: block; }
	.testimonial__company {
		font-size: 18px;
		font-size: 1.125rem;
		color: #909090; }

/*-------------------------------------------------------*/
/* Clients
/*-------------------------------------------------------*/
.client-logo {
	border-bottom: 1px solid #E9E9E9;
	text-align: center; }
	@media only screen and (min-width: 576px) {
		.client-logo {
			border-right: 1px solid #E9E9E9; } }
	.client-logo:last-child {
		border-right: none; }

@media only screen and (min-width: 576px) {
	.clients-second-row .client-logo {
		border-bottom: none; } }

@media only screen and (max-width: 575px) {
	.clients-second-row .client-logo:last-child {
		border-bottom: none; } }

/*-------------------------------------------------------*/
/* Team
/*-------------------------------------------------------*/
.team {
	margin-bottom: 68px; }
	.team-subtitle {
		max-width: 960px;
		margin-bottom: 96px;
		font-size: 24px;
		font-size: 1.5rem; }
	.team__img {
		width: 100%; }
	.team__name {
		font-size: 30px;
		font-size: 1.875rem;
		margin-top: 56px; }
		@media only screen and (min-width: 1025px) {
			.team__name {
				font-size: 38px;
				font-size: 2.375rem; } }
	.team__email {
		font-size: 24px;
		font-size: 1.5rem; }
		@media only screen and (min-width: 1025px) {
			.team__email {
				font-size: 30px;
				font-size: 1.875rem; } }

/*-------------------------------------------------------*/
/* Call To Action
/*-------------------------------------------------------*/
.call-to-action {
	max-width: 880px;
	margin-left: auto;
	margin-right: auto;
	text-align: center; }
	.call-to-action__title {
		font-size: 32px;
		font-size: 2rem;
		line-height: 1.2;
		margin-bottom: 56px; }
		@media only screen and (min-width: 1025px) {
			.call-to-action__title {
				font-size: 40px;
				font-size: 2.5rem; } }

/*-------------------------------------------------------*/
/* Contact Form
/*-------------------------------------------------------*/
.contact-form-modal {
	padding: 0 !important; }
	.contact-form-modal__dialog {
		display: flex;
		margin: 0;
		max-width: 100%; }
	.contact-form-modal__content {
		border-radius: 0;
		border: 0;
		justify-content: center;
		flex-direction: row;
		height: 100vh; }
	.contact-form-modal__body {
		flex: 1 0 0;
		max-width: 555px;
		margin: auto; }
	.contact-form-modal__close {
		width: 40px;
		height: 40px;
		position: absolute;
		top: 40px;
		right: 40px; }
		.contact-form-modal__close span {
			font-size: 0; }
			.contact-form-modal__close span:before, .contact-form-modal__close span:after {
				content: '';
				display: block;
				position: absolute;
				top: 20px;
				height: 2px;
				width: 40px;
				background-color: #131313; }
			.contact-form-modal__close span:before {
				-webkit-transform: rotate(-45deg);
				-moz-transform: rotate(-45deg);
				-ms-transform: rotate(-45deg);
				-o-transform: rotate(-45deg);
				transform: rotate(-45deg); }
			.contact-form-modal__close span:after {
				-webkit-transform: rotate(45deg);
				-moz-transform: rotate(45deg);
				-ms-transform: rotate(45deg);
				-o-transform: rotate(45deg);
				transform: rotate(45deg); }
		.contact-form-modal__close:hover, .contact-form-modal__close:focus {
			background-color: transparent; }

.contact-form__message {
	height: 50px;
	width: 100%;
	font-size: 13px;
	line-height: 50px;
	text-align: center;
	float: none;
	margin-top: 20px;
	display: none;
	color: #fff; }
	.contact-form__message.error {
		background-color: #f8d7da;
		color: #721c24; }
	.contact-form__message.success {
		background-color: #d4edda;
		color: #155724; }

/*-------------------------------------------------------*/
/* Newsletter
/*-------------------------------------------------------*/
.newsletter {
	/* Change Color of Placeholders */ }
	.newsletter .title-row {
		margin-bottom: 20px; }
	.newsletter .section-title {
		font-size: 36px;
		margin-bottom: 30px; }
	.newsletter .subtitle {
		font-size: 21px;
		font-weight: 400; }
	.newsletter__form {
		max-width: 460px;
		margin-left: auto;
		margin-right: auto; }
	.newsletter .mc4wp-form-fields {
		display: -webkit-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex; }
		.newsletter .mc4wp-form-fields > .form-group:first-child {
			flex: 1;
			margin-right: 8px; }
			@media only screen and (max-width: 575px) {
				.newsletter .mc4wp-form-fields > .form-group:first-child {
					margin-right: 0; } }
		@media only screen and (max-width: 575px) {
			.newsletter .mc4wp-form-fields {
				display: block; } }
	.newsletter input {
		height: 50px; }
	.newsletter input[type=email],
	.newsletter input[type=text] {
		color: #fff;
		background-color: transparent;
		border-color: rgba(213, 217, 237, 0.36); }
		.newsletter input[type=email]:focus,
		.newsletter input[type=text]:focus {
			border-color: #fff; }
	.newsletter input[type=email]::-webkit-input-placeholder,
	.newsletter input[type=text]::-webkit-input-placeholder {
		color: #fff; }
	.newsletter input[type=email]:-moz-placeholder,
	.newsletter input[type=text]:-moz-placeholder {
		color: #fff;
		opacity: 1; }
	.newsletter input[type=email]::-moz-placeholder,
	.newsletter input[type=text]::-moz-placeholder {
		color: #fff;
		opacity: 1; }
	.newsletter input[type=email]:-ms-input-placeholder,
	.newsletter input[type=text]:-ms-input-placeholder {
		color: #fff; }

/*-------------------------------------------------------*/
/* Page Title
/*-------------------------------------------------------*/
.page-title {
	position: relative;
	padding: 120px 0; }
	@media only screen and (max-width: 767px) {
		.page-title {
			padding: 60px 0; } }
	.page-title__holder {
		max-width: 860px; }
	.page-title__title {
		font-size: 32px;
		font-size: 2rem;
		line-height: 1.3;
		margin-bottom: 28px; }
		@media only screen and (min-width: 1025px) {
			.page-title__title {
				font-size: 56px;
				font-size: 3.5rem; } }
	.page-title__subtitle {
		font-size: 24px;
		font-size: 1.5rem;
		line-height: 1.4; }

/*-------------------------------------------------------*/
/* Widgets
/*-------------------------------------------------------*/
.widget {
	margin-bottom: 48px; }
	.widget li {
		list-style: none; }
	.widget-title {
		margin-bottom: 24px;
		font-size: 1rem;
		font-weight: 700; }

/* Widget CTA
-------------------------------------------------------*/
.widget-cta__title, .widget-cta__link {
	font-size: 34px;
	font-size: 2.125rem; }
	@media only screen and (min-width: 1025px) {
		.widget-cta__title, .widget-cta__link {
			font-size: 52px;
			font-size: 3.25rem; } }

.widget-cta__title {
	color: #ffffff; }
	.widget-cta__title a {
		display: block; }

.widget-cta__link {
	color: #ffc62d; }

/* Widget About Us
-------------------------------------------------------*/
.widget-about-us {
	font-size: 20px;
	font-size: 1.25rem; }
	.widget-about-us__address {
		margin-bottom: 28px; }
	.widget-about-us__address, .widget-about-us__email, .widget-about-us__phone {
		font-family: "aktiv-grotesk-extended", "Montserrat", sans-serif;
		font-weight: 700; }
	.widget-about-us__email, .widget-about-us__phone {
		display: block;
		margin-bottom: 16px; }
	.widget-about-us__address {
		color: #fff; }

/* Widget Nav Menu
-------------------------------------------------------*/
.widget-nav-menu li a {
	font-family: "aktiv-grotesk-extended", "Montserrat", sans-serif;
	font-weight: 700;
	font-size: 20px;
	font-size: 1.25rem; }

/* Widget Tags / Entry Tags
-------------------------------------------------------*/
.entry__tags a {
	line-height: 1;
	margin: 0 14px 14px 0;
	font-size: 0.75rem !important;
	font-weight: 700;
	color: #000000;
	font-family: "aktiv-grotesk-extended", "Montserrat", sans-serif;
	float: left;
	border-bottom: 1px solid #b5b5b5;
	transition: all 0.1s ease-in-out; }
	@media (prefers-reduced-motion: reduce) {
		.entry__tags a {
			transition: none; } }
	.entry__tags a:hover, .entry__tags a:focus {
		color: #0052f8; }

/*-------------------------------------------------------*/
/* Block Editor Front-end Styles
/*-------------------------------------------------------*/
/* Buttons
-------------------------------------------------------*/
.wp-block-button .wp-block-button__link {
	padding: 14px 32px;
	font-size: 0.9375rem;
	border-radius: 5px;
	font-weight: 700;
	line-height: 1.5 !important; }

.wp-block-button__link:not(.has-background),
.wp-block-button__link:not(.has-background):active,
.wp-block-button__link:not(.has-background):focus,
.wp-block-button__link:not(.has-background):hover {
	background-color: #ffc62d; }

/* Full-width Images
-------------------------------------------------------*/
@media only screen and (min-width: 1025px) {
	.dorya-fullwidth .entry__article .alignwide {
		margin-left: -140px;
		margin-right: -140px;
		width: auto;
		position: relative;
		z-index: 1; }
	.dorya-fullwidth .entry__article .alignfull {
		margin-left: calc( 50% - 50vw);
		margin-right: calc( 50% - 50vw);
		max-width: 100vw;
		position: relative;
		z-index: 1; }
		.dorya-fullwidth .entry__article .alignfull img {
			width: 100vw; } }

/* Images
-------------------------------------------------------*/
.wp-block-image {
	margin-top: 40px;
	margin-bottom: 40px; }
	@media only screen and (min-width: 1025px) {
		.wp-block-image {
			margin-top: 80px;
			margin-bottom: 80px; } }
	.wp-block-image .alignleft {
		margin-right: 2em;
		max-width: 40%; }
		@media only screen and (max-width: 575px) {
			.wp-block-image .alignleft {
				float: none;
				margin-right: 0; } }
	.wp-block-image .alignright {
		margin-left: 2em;
		max-width: 40%; }
		@media only screen and (max-width: 575px) {
			.wp-block-image .alignright {
				float: none;
				margin-left: 0; } }

/* Tables
-------------------------------------------------------*/
.wp-block-table {
	width: 100%;
	margin-bottom: 2rem; }

/* Latest Comments
-------------------------------------------------------*/
ol.wp-block-latest-comments {
	padding-left: 0; }

/* Galleries
-------------------------------------------------------*/
ul.wp-block-gallery {
	list-style: none;
	padding-left: 0 !important; }

.wp-block-image figcaption {
	color: #909090; }

.wp-block-gallery .blocks-gallery-image figcaption,
.wp-block-gallery .blocks-gallery-item figcaption {
	position: absolute;
	bottom: 0;
	width: 100%;
	max-height: 100%;
	overflow: auto;
	padding: 40px 10px 9px;
	color: #fff;
	text-align: center;
	font-size: 13px;
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3) 70%, transparent); }

/* Separators
-------------------------------------------------------*/
hr {
	margin-top: 20px;
	margin-bottom: 20px;
	border: 0;
	border-top: 1px solid #E9E9E9; }

.wp-block-separator.is-style-dots {
	background: 0 0;
	border: none;
	text-align: center;
	max-width: none;
	line-height: 1;
	height: auto; }
	.wp-block-separator.is-style-dots::before {
		content: "\00b7 \00b7 \00b7";
		color: #909090;
		font-size: 1.25rem;
		font-weight: 700;
		letter-spacing: 2em;
		padding-left: 2em;
		font-family: serif; }

.wp-block-separator.is-style-default {
	max-width: 100px; }

.wp-block-separator.is-style-dots:before {
	font-weight: 700; }

/* Blockquote / Pullquote
-------------------------------------------------------*/
blockquote {
	margin: 0;
	padding-left: 40px;
	line-height: 1.4; }
	blockquote p {
		margin-bottom: 0; }
	blockquote cite {
		color: #909090;
		font-size: 16px;
		font-size: 1rem;
		font-weight: 700;
		margin-top: 1em;
		display: inline-block;
		font-style: normal; }
	blockquote::before {
		content: '“';
		font-family: "aktiv-grotesk-extended", "Montserrat", sans-serif;
		font-size: 44px;
		font-size: 2.75rem;
		font-weight: 700;
		line-height: 1;
		display: block;
		position: absolute;
		left: 0; }

.wp-block-quote {
	margin-bottom: 40px; }
	.wp-block-quote p {
		font-size: 30px;
		font-size: 1.875rem;
		font-weight: 700;
		font-family: "aktiv-grotesk-extended", "Montserrat", sans-serif;
		color: #000000; }

.wp-block-pullquote {
	padding: 0;
	margin-bottom: 40px;
	text-align: left;
	color: #000000;
	position: relative; }
	.wp-block-pullquote p {
		font-family: "aktiv-grotesk-extended", "Montserrat", sans-serif;
		font-weight: 700; }
	@media only screen and (min-width: 1025px) {
		.wp-block-pullquote.alignleft, .wp-block-pullquote.alignright {
			margin-top: 0; }
			.wp-block-pullquote.alignleft p, .wp-block-pullquote.alignright p {
				font-size: 1.25rem; }
		.wp-block-pullquote.alignleft {
			margin-left: -140px;
			margin-right: 2em; }
		.wp-block-pullquote.alignright {
			margin-right: -140px;
			margin-left: 2em; } }
	@media only screen and (max-width: 575px) {
		.wp-block-pullquote.alignleft, .wp-block-pullquote.alignright {
			max-width: 100%;
			float: none;
			margin-left: 0;
			margin-right: 0; } }

.wp-block-pullquote.is-style-solid-color {
	border: none; }
	.wp-block-pullquote.is-style-solid-color blockquote p {
		margin-bottom: 0; }
	.wp-block-pullquote.is-style-solid-color.alignleft blockquote p,
	.wp-block-pullquote.is-style-solid-color.alignright blockquote p {
		font-size: 1.25rem; }

.wp-block-pullquote.is-style-solid-color blockquote {
	margin-left: auto;
	margin-right: auto;
	text-align: left;
	max-width: 60%; }

.wp-block-gallery + .wp-block-gallery {
	margin-top: -40px; }

.wp-block-gallery {
	display: flex;
	flex-wrap: wrap;
	list-style-type: none;
	padding: 0; }

.wp-block-gallery .blocks-gallery-image, .wp-block-gallery .blocks-gallery-item {
	margin: 0 16px 16px 0;
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	justify-content: center;
	position: relative; }

.wp-block-gallery .blocks-gallery-image:nth-of-type(even), .wp-block-gallery .blocks-gallery-item:nth-of-type(even) {
	margin-right: 0; }

.wp-block-gallery .blocks-gallery-image, .wp-block-gallery .blocks-gallery-item {
	width: calc((100% - 16px)/ 2); }

@media (min-width: 600px) {
	.wp-block-gallery.columns-3 .blocks-gallery-image, .wp-block-gallery.columns-3 .blocks-gallery-item {
		width: calc((100% - 16px * 2)/ 3);
		margin-right: 16px; } }

.wp-block-gallery .blocks-gallery-image:last-child, .wp-block-gallery .blocks-gallery-item:last-child {
	margin-right: 0; }

.wp-block-gallery .blocks-gallery-image figure, .wp-block-gallery .blocks-gallery-item figure {
	margin: 0;
	height: 100%; }

.wp-block-gallery.is-cropped .blocks-gallery-image a, .wp-block-gallery.is-cropped .blocks-gallery-image img, .wp-block-gallery.is-cropped .blocks-gallery-item a, .wp-block-gallery.is-cropped .blocks-gallery-item img {
	width: 100%; }

.wp-block-gallery .blocks-gallery-image figure, .wp-block-gallery .blocks-gallery-item figure {
	display: flex;
	align-items: flex-end;
	justify-content: flex-start; }

.wp-block-columns {
	display: flex;
	flex-wrap: wrap; }

@media (min-width: 782px) {
	.wp-block-columns {
		flex-wrap: nowrap; } }

@media (min-width: 600px) {
	.wp-block-column:not(:last-child) {
		margin-right: 32px; } }

@media (min-width: 600px) {
	.wp-block-column:nth-child(odd) {
		margin-right: 32px; } }

@media (min-width: 600px) {
	.wp-block-column {
		flex-basis: 50%;
		flex-grow: 0; } }

@media (min-width: 600px) {
	.wp-block-column:not(:first-child) {
		margin-left: 32px; } }

.wp-block-column {
	flex: 1;
	margin-bottom: 1em;
	flex-basis: 100%;
	min-width: 0;
	word-break: break-word;
	overflow-wrap: break-word; }

.cc-window {
	opacity: 1;
	transition: opacity 1s ease; }

.cc-window.cc-invisible {
	opacity: 0; }

.cc-animate.cc-revoke {
	transition: transform 1s ease; }

.cc-animate.cc-revoke.cc-top {
	transform: translateY(-2em); }

.cc-animate.cc-revoke.cc-bottom {
	transform: translateY(2em); }

.cc-animate.cc-revoke.cc-active.cc-bottom, .cc-animate.cc-revoke.cc-active.cc-top, .cc-revoke:hover {
	transform: translateY(0); }

.cc-grower {
	max-height: 0;
	overflow: hidden;
	transition: max-height 1s; }

.cc-link, .cc-revoke:hover {
	text-decoration: underline; }

.cc-revoke, .cc-window {
	position: fixed;
	overflow: hidden;
	box-sizing: border-box;
	font-family: Helvetica,Calibri,Arial,sans-serif;
	font-size: 16px;
	line-height: 1.5em;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	z-index: 9999; }

.cc-window.cc-static {
	position: static; }

.cc-window.cc-floating {
	padding: 2em;
	max-width: 24em;
	-ms-flex-direction: column;
	flex-direction: column; }

.cc-window.cc-banner {
	padding: 1em 1.8em;
	width: 100%;
	-ms-flex-direction: row;
	flex-direction: row; }

.cc-revoke {
	padding: .5em; }

.cc-header {
	font-size: 18px;
	font-weight: 700; }

.cc-btn, .cc-close, .cc-link, .cc-revoke {
	cursor: pointer; }

.cc-link {
	opacity: .8;
	display: inline-block;
	padding: .2em; }

.cc-link:hover {
	opacity: 1; }

.cc-link:active, .cc-link:visited {
	color: initial; }

.cc-btn {
	display: block;
	padding: .4em .8em;
	font-size: .9em;
	font-weight: 700;
	border-width: 2px;
	border-style: solid;
	text-align: center;
	white-space: nowrap; }

.cc-highlight .cc-btn:first-child {
	background-color: transparent;
	border-color: transparent; }

.cc-highlight .cc-btn:first-child:focus, .cc-highlight .cc-btn:first-child:hover {
	background-color: transparent;
	text-decoration: underline; }

.cc-close {
	display: block;
	position: absolute;
	top: .5em;
	right: .5em;
	font-size: 1.6em;
	opacity: .9;
	line-height: .75; }

.cc-close:focus, .cc-close:hover {
	opacity: 1; }

.cc-revoke.cc-top {
	top: 0;
	left: 3em;
	border-bottom-left-radius: .5em;
	border-bottom-right-radius: .5em; }

.cc-revoke.cc-bottom {
	bottom: 0;
	left: 3em;
	border-top-left-radius: .5em;
	border-top-right-radius: .5em; }

.cc-revoke.cc-left {
	left: 3em;
	right: unset; }

.cc-revoke.cc-right {
	right: 3em;
	left: unset; }

.cc-top {
	top: 1em; }

.cc-left {
	left: 1em; }

.cc-right {
	right: 1em; }

.cc-bottom {
	bottom: 1em; }

.cc-floating > .cc-link {
	margin-bottom: 1em; }

.cc-floating .cc-message {
	display: block;
	margin-bottom: 1em; }

.cc-window.cc-floating .cc-compliance {
	-ms-flex: 1 0 auto;
	flex: 1 0 auto; }

.cc-window.cc-banner {
	-ms-flex-align: center;
	align-items: center; }

.cc-banner.cc-top {
	left: 0;
	right: 0;
	top: 0; }

.cc-banner.cc-bottom {
	left: 0;
	right: 0;
	bottom: 0; }

.cc-banner .cc-message {
	display: block;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	max-width: 100%;
	margin-right: 1em; }

.cc-compliance {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-line-pack: justify;
	align-content: space-between; }

.cc-floating .cc-compliance > .cc-btn {
	-ms-flex: 1;
	flex: 1; }

.cc-btn + .cc-btn {
	margin-left: .5em; }

@media print {
	.cc-revoke, .cc-window {
		display: none; } }

@media screen and (max-width: 900px) {
	.cc-btn {
		white-space: normal; } }

@media screen and (max-width: 414px) and (orientation: portrait), screen and (max-width: 736px) and (orientation: landscape) {
	.cc-window.cc-top {
		top: 0; }
	.cc-window.cc-bottom {
		bottom: 0; }
	.cc-window.cc-banner, .cc-window.cc-floating, .cc-window.cc-left, .cc-window.cc-right {
		left: 0;
		right: 0; }
	.cc-window.cc-banner {
		-ms-flex-direction: column;
		flex-direction: column; }
	.cc-window.cc-banner .cc-compliance {
		-ms-flex: 1 1 auto;
		flex: 1 1 auto; }
	.cc-window.cc-floating {
		max-width: none; }
	.cc-window .cc-message {
		margin-bottom: 1em; }
	.cc-window.cc-banner {
		-ms-flex-align: unset;
		align-items: unset; }
	.cc-window.cc-banner .cc-message {
		margin-right: 0; } }

.cc-floating.cc-theme-classic {
	padding: 1.2em;
	border-radius: 5px; }

.cc-floating.cc-type-info.cc-theme-classic .cc-compliance {
	text-align: center;
	display: inline;
	-ms-flex: none;
	flex: none; }

.cc-theme-classic .cc-btn {
	border-radius: 5px; }

.cc-theme-classic .cc-btn:last-child {
	min-width: 140px; }

.cc-floating.cc-type-info.cc-theme-classic .cc-btn {
	display: inline-block; }

.cc-theme-edgeless.cc-window {
	padding: 0; }

.cc-floating.cc-theme-edgeless .cc-message {
	margin: 2em 2em 1.5em; }

.cc-banner.cc-theme-edgeless .cc-btn {
	margin: 0;
	padding: .8em 1.8em;
	height: 100%; }

.cc-banner.cc-theme-edgeless .cc-message {
	margin-left: 1em; }

.cc-floating.cc-theme-edgeless .cc-btn + .cc-btn {
	margin-left: 0; }

/* Cookies banner
-------------------------------------------------------*/
.cc-container {
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	justify-content: center;
	padding: 0; }

.cc-window {
	background-color: #000000 !important;
	padding: 5px 20px; }

.cc-message {
	margin-right: 20px;
	flex: 0 1 auto !important;
	color: #fff;
	font-weight: 500;
	font-size: 15px; }

.cc-link {
	opacity: 1;
	font-weight: 700; }
	.cc-link:hover, .cc-link:focus {
		color: #fff; }

a.cc-btn.cc-dismiss {
	background-color: #fff;
	border-radius: 0;
	height: 32px;
	line-height: 32px;
	padding: 0 14px;
	border: 0;
	color: #131313;
	min-width: auto !important; }
	a.cc-btn.cc-dismiss:hover, a.cc-btn.cc-dismiss:focus {
		background-color: #ffc21e; }

/*-------------------------------------------------------*/
/* Contact Page
/*-------------------------------------------------------*/
.section-contact-info {
	padding-top: 24px;
	padding-bottom: 100px; }
	@media only screen and (min-width: 1025px) {
		.section-contact-info {
			padding-bottom: 160px; } }

.contact-info {
	font-family: "aktiv-grotesk-extended", "Montserrat", sans-serif;
	font-weight: 700;
	margin-bottom: 48px; }
	.contact-info a,
	.contact-info address,
	.contact-info span {
		font-size: 20px;
		font-size: 1.25rem;
		line-height: 1.7; }
	.contact-info a:hover {
		color: #0052f8; }
	.contact-info__title {
		font-size: 24px;
		font-size: 1.5rem;
		margin-bottom: 26px; }
	.contact-info__email, .contact-info__phone {
		display: block;
		color: #262626; }

/*-------------------------------------------------------*/
/* Page 404
/*-------------------------------------------------------*/
.page-404-section {
	padding-bottom: 120px; }
	@media only screen and (min-width: 1025px) {
		.page-404-section {
			padding-bottom: 200px; } }

.page-404__number {
	-webkit-text-fill-color: transparent;
	-webkit-background-clip: text;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	color: #d3b5ed;
	font-family: "aktiv-grotesk-extended", "Montserrat", sans-serif;
	font-weight: 700;
	line-height: 1;
	font-size: 120px;
	font-size: 7.5rem; }
	@media only screen and (min-width: 1025px) {
		.page-404__number {
			font-size: 300px;
			font-size: 18.75rem; } }

.page-404__title {
	margin-top: 32px;
	margin-bottom: 28px;
	font-size: 36px;
	font-size: 2.25rem; }
	@media only screen and (min-width: 1025px) {
		.page-404__title {
			font-size: 44px;
			font-size: 2.75rem; } }

.page-404__text {
	margin-bottom: 48px; }

/*-------------------------------------------------------*/
/* Single Post
/*-------------------------------------------------------*/
/* Page Title
-------------------------------------------------------*/
.blog-page-title {
	position: relative;
	margin-bottom: 0; }
	.blog-page-title__holder {
		padding: 40px 0; }
	.blog-page-title__title {
		line-height: 1.2;
		margin-top: 12px;
		margin-bottom: 38px; }

/* Featured Image
-------------------------------------------------------*/
.blog-featured-image {
	max-width: 88%; }
	.blog-featured-image-holder {
		margin: 0;
		background-color: #fff; }

/* Article
-------------------------------------------------------*/
.single-post__entry {
	overflow: visible; }

@media only screen and (max-width: 1024px) {
	.entry__article {
		margin-top: 16px; } }

.entry__article > p:first-of-type {
	font-size: 36px;
	font-size: 2.25rem;
	margin-bottom: 56px; }

.entry__article > h1,
.entry__article > h2,
.entry__article > h3,
.entry__article > h4,
.entry__article > h5,
.entry__article > h6 {
	margin-top: 40px;
	margin-bottom: 20px; }

.entry__article > p, .entry__article ul, .entry__article ol {
	margin-bottom: 2.5rem; }

.entry__article a:not(.wp-block-button__link) {
	color: #0052f8; }
	.entry__article a:not(.wp-block-button__link):hover {
		color: #000000; }

.entry__article ol, .entry__article ul {
	padding-left: 56px; }

.dropcap {
	float: left;
	color: #000000;
	font-weight: 700;
	font-size: 6.875rem;
	line-height: 1;
	padding-right: 20px; }

/* Entry Share
/*-------------------------------------------------------*/
.entry__article-wrap {
	position: relative; }

@media only screen and (min-width: 1025px) {
	.entry__share {
		text-align: right; } }

/* Entry Tags
/*-------------------------------------------------------*/
.entry__tags {
	margin-top: 8px;
	margin-bottom: 24px;
	overflow: hidden; }
	.entry__tags-label {
		color: #000000;
		font-weight: 700;
		font-size: 18px;
		display: inline-block;
		margin-right: 4px; }

/* Comments
/*-------------------------------------------------------*/
.entry__comments {
	margin-top: 64px; }

.entry__comments-title,
.comment-respond__title {
	font-size: 2rem;
	margin-bottom: 38px; }

.comment {
	list-style: none; }
	@media only screen and (min-width: 576px) {
		.comment .children {
			padding-left: 8%; } }
	.comment-list > li:first-child > .comment-body {
		border-top: 0;
		padding-top: 0; }
	.comment-body {
		margin-top: 32px;
		padding-bottom: 28px; }
	.comment-text {
		overflow: hidden; }
		.comment-text p {
			margin-bottom: 10px; }
	.comment-avatar {
		float: left;
		margin-right: 20px;
		display: block;
		border-radius: 50%;
		overflow: hidden; }
	.comment-author {
		font-family: "aktiv-grotesk-extended", "Montserrat", sans-serif;
		font-size: 0.9375rem;
		display: inline-block;
		margin-bottom: 10px;
		line-height: 1.2; }
	.comment-content {
		font-size: 18px;
		font-size: 1.125rem; }
	.comment-metadata {
		line-height: 1.2;
		margin-bottom: 16px; }
		.comment-metadata a {
			display: block; }
	.comment-date, .comment-edit-link {
		display: inline-block;
		margin-bottom: 14px;
		font-size: 0.875rem;
		color: #262626; }
	.comment-edit-link {
		margin-left: 5px;
		margin-bottom: 0;
		color: #ffc62d; }
	.comment-reply {
		font-size: 16px;
		font-size: 1rem;
		font-weight: 700; }
		.comment-reply-link {
			color: #000000; }
			.comment-reply-link:hover {
				color: #0052f8; }

/* Comment Form
/*-------------------------------------------------------*/
.comment-form p {
	margin: 0; }

.comment-respond {
	margin-top: 64px; }

/*-------------------------------------------------------*/
/* Single Project
/*-------------------------------------------------------*/
/* Hero
-------------------------------------------------------*/
.project__hero {
	height: 420px;
	margin-top: 60px; }
	@media only screen and (min-width: 1025px) {
		.project__hero {
			height: 100vh;
			margin-top: 0; } }

.project__hero-img-holder {
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	height: 100%;
	margin: 0; }

/* Project Info
-------------------------------------------------------*/
.project-info-item {
	list-style: none;
	margin-bottom: 24px; }
	.project-info-item__label {
		font-family: "aktiv-grotesk-extended", "Montserrat", sans-serif;
		font-weight: 700; }
	.project-info-item__title {
		display: block;
		color: #818181; }
	.project-info-item a {
		color: #818181; }
		.project-info-item a:hover, .project-info-item a:focus {
			color: #0052f8; }

/* Project Description
-------------------------------------------------------*/
.project-description__link {
	font-size: 24px;
	font-size: 1.5rem;
	color: #000000; }
	.project-description__link i {
		font-size: 12px;
		display: inline-block;
		margin-left: 3px; }
	.project-description__link:hover, .project-description__link:focus {
		color: #0052f8; }

/* Process
-------------------------------------------------------*/
.project-process__text:last-child {
	margin-bottom: 0; }

/* Mobile
-------------------------------------------------------*/
.section-project-mobile {
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat; }

.project-mobile__img {
	margin-top: 32px;
	margin-bottom: -420px;
	z-index: 1;
	position: relative; }

/* Results
-------------------------------------------------------*/
.section-project-results {
	padding-top: 400px; }

.project-results__title {
	font-size: 38px;
	font-size: 2.375rem; }

.project-results__numbers {
	padding-bottom: 80px; }

.project-results__list {
	list-style: none;
	display: flex;
	justify-content: space-between;
	margin-left: -10px;
	margin-right: -10px;
	margin-bottom: 70px; }

.project-results__number {
	font-family: "aktiv-grotesk-extended", "Montserrat", sans-serif;
	font-size: 32px;
	font-size: 2rem;
	font-weight: 700;
	display: block; }
	@media only screen and (min-width: 1025px) {
		.project-results__number {
			font-size: 60px;
			font-size: 3.75rem; } }

.project-results__label {
	font-size: 16px;
	font-size: 1rem; }

.project-results__item {
	flex: 1 0 0;
	padding: 0 10px;
	text-align: center; }

.project-results__divider {
	width: 289px;
	margin: 0 auto;
	background-color: #6a6a6a; }

/* Gallery
-------------------------------------------------------*/
.project__gallery-img-holder {
	margin-bottom: 10px; }

.project__gallery-item {
	width: 100%; }

/* Related Projects
-------------------------------------------------------*/
.section-related-projects {
	padding: 80px 0; }
	@media only screen and (min-width: 1025px) {
		.section-related-projects {
			padding: 260px 0; } }
	.section-related-projects:hover::before {
		background-color: rgba(19, 19, 19, 0.5); }

.related-project {
	position: relative;
	z-index: 1;
	pointer-events: none; }
	.related-project__link {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%; }
		.related-project__link:hover + .related-project .related-project__arrow {
			right: 60px; }
	.related-project__body {
		max-width: 600px;
		margin-left: auto;
		margin-right: auto; }
		@media only screen and (max-width: 1024px) {
			.related-project__body {
				margin-left: 0;
				padding-right: 80px; } }
	.related-project__label {
		font-size: 16px;
		font-size: 1rem;
		display: inline-block;
		text-transform: uppercase;
		letter-spacing: 0.04em;
		background-color: #131313;
		padding: 12px 24px;
		color: #fff;
		margin-bottom: 18px; }
	.related-project__title {
		font-size: 32px;
		font-size: 2rem;
		color: #fff; }
		@media only screen and (min-width: 1025px) {
			.related-project__title {
				font-size: 48px;
				font-size: 3rem; } }
	.related-project__arrow {
		position: absolute;
		right: 30px;
		transition: all 0.4s cubic-bezier(0.25, 0.74, 0.22, 0.99);
		transform: translateY(-50%);
		top: 50%;
		fill: #fff; }
		@media (prefers-reduced-motion: reduce) {
			.related-project__arrow {
				transition: none; } }
		@media only screen and (max-width: 1024px) {
			.related-project__arrow {
				width: 50px;
				right: 10px; } }

/*-------------------------------------------------------*/
/* Spacings
/*-------------------------------------------------------*/
.m-0 {
	margin: 0 !important; }

.p-0 {
	padding: 0 !important; }

.mt-0 {
	margin-top: 0 !important; }

.mt-8 {
	margin-top: 8px; }

.mt-16 {
	margin-top: 16px; }

.mt-24 {
	margin-top: 24px; }

.mt-32 {
	margin-top: 32px; }

.mt-40 {
	margin-top: 40px; }

.mt-48 {
	margin-top: 48px; }

.mt-56 {
	margin-top: 56px; }

.mt-64 {
	margin-top: 64px; }

.mt-72 {
	margin-top: 72px; }

.mt-80 {
	margin-top: 80px; }

.mb-0 {
	margin-bottom: 0 !important; }

.mb-8 {
	margin-bottom: 8px; }

.mb-16 {
	margin-bottom: 16px; }

.mb-24 {
	margin-bottom: 24px; }

.mb-32 {
	margin-bottom: 32px; }

.mb-40 {
	margin-bottom: 40px; }

.mb-48 {
	margin-bottom: 48px; }

.mb-56 {
	margin-bottom: 56px; }

.mb-64 {
	margin-bottom: 64px; }

.mb-72 {
	margin-bottom: 72px; }

.mb-80 {
	margin-bottom: 80px; }

.pt-0 {
	padding-top: 0; }

.pt-8 {
	padding-top: 8px; }

.pt-16 {
	padding-top: 16px; }

.pt-24 {
	padding-top: 24px; }

.pt-32 {
	padding-top: 32px; }

.pt-40 {
	padding-top: 40px; }

.pt-48 {
	padding-top: 48px; }

.pt-56 {
	padding-top: 56px; }

.pt-64 {
	padding-top: 64px; }

.pt-72 {
	padding-top: 72px; }

.pt-80 {
	padding-top: 80px; }

.pt-96 {
	padding-top: 96px; }

.pb-0 {
	padding-bottom: 0; }

.pb-8 {
	padding-bottom: 8px; }

.pb-16 {
	padding-bottom: 16px; }

.pb-24 {
	padding-bottom: 24px; }

.pb-32 {
	padding-bottom: 32px; }

.pb-40 {
	padding-bottom: 40px; }

.pb-48 {
	padding-bottom: 48px; }

.pb-56 {
	padding-bottom: 56px; }

.pb-64 {
	padding-bottom: 64px; }

.pb-72 {
	padding-bottom: 72px; }

.pb-80 {
	padding-bottom: 80px; }

.pb-88 {
	padding-bottom: 88px; }

.pb-96 {
	padding-bottom: 96px; }

/*-------------------------------------------------------*/
/* Helper classes
/*-------------------------------------------------------*/
.clear {
	clear: both; }

.oh {
	overflow: hidden; }

.ov {
	overflow: visible; }

.relative {
	position: relative; }

.mobile section {
	background-attachment: scroll !important; }

.bg-dark {
	background-color: #131313; }

.bg-black {
	background-color: #000; }

.bg-light {
	background-color: #F6F6F6; }

.bg-white {
	background-color: #fff; }

.bg-color {
	background-color: #0052f8; }

.block {
	display: block; }

.left {
	float: left; }

.right {
	float: right; }

.valign {
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%); }

.img-full-width {
	width: 100%; }

.uppercase {
	text-transform: uppercase;
	letter-spacing: 0.02em; }

.last {
	margin-bottom: 0 !important; }

/* Hovers
-------------------------------------------------------*/
.hover-shrink--shrink {
	overflow: hidden; }

.hover-shrink--shrink, .hover-shrink--zoom {
	transition: all 0.2s ease-in-out; }
	@media (prefers-reduced-motion: reduce) {
		.hover-shrink--shrink, .hover-shrink--zoom {
			transition: none; } }

.hover-shrink:hover .hover-shrink--shrink {
	-webkit-transform: scale(0.95);
	-moz-transform: scale(0.95);
	-ms-transform: scale(0.95);
	-o-transform: scale(0.95);
	transform: scale(0.95); }

.hover-shrink:hover .hover-shrink--zoom {
	-webkit-transform: scale(1.2);
	-moz-transform: scale(1.2);
	-ms-transform: scale(1.2);
	-o-transform: scale(1.2);
	transform: scale(1.2); }

.hover-down {
	transition: all 0.4s cubic-bezier(0.25, 0.74, 0.22, 0.99);
	top: 0;
	position: relative; }
	@media (prefers-reduced-motion: reduce) {
		.hover-down {
			transition: none; } }
	.hover-down:hover {
		box-shadow: 0px 5px 10px rgba(64, 90, 128, 0.05);
		top: 2px; }

.hover-line {
	position: relative; }
	.hover-line:after {
		content: '';
		display: block;
		height: 2px;
		width: 100%;
		position: absolute;
		bottom: -5px;
		right: 0;
		background-color: #ffc62d;
		transition: all 0.4s cubic-bezier(0.25, 0.74, 0.22, 0.99); }
		@media (prefers-reduced-motion: reduce) {
			.hover-line:after {
				transition: none; } }
	.hover-line:hover:after {
		width: 0; }

/* Title link / Underline
-------------------------------------------------------*/
.title-link {
	font-family: "aktiv-grotesk-extended", "Montserrat", sans-serif;
	color: #000000;
	font-weight: 700;
	font-size: 18px;
	font-size: 1.125rem; }

.underline {
	background-image: linear-gradient(to right, #000000 0%, #000000 100%);
	background-size: 100% 2px;
	background-position: 0px 95%;
	background-repeat: no-repeat;
	transition: all 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
	padding: 0.5% 0px; }

.title-underline a {
	background-image: linear-gradient(to right, #000000 0%, #000000 100%);
	background-size: 0px 2px;
	background-position: 0px 95%;
	background-repeat: no-repeat;
	transition: all 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
	padding: 0.5% 0px; }

.title-underline:hover a {
	background-size: 100% 2px; }

/* Gradients / Overlays
-------------------------------------------------------*/
.bg-gradient-bottom:before {
	content: "";
	z-index: 1;
	top: 0;
	display: block;
	position: absolute;
	width: 100%;
	height: 100%;
	transition: background-color 0.4s cubic-bezier(0.25, 0.74, 0.22, 0.99);
	background: rgba(19, 19, 19, 0.38);
	/* Old browsers */
	background: -moz-linear-gradient(bottom, rgba(19, 19, 19, 0.38) 0%, transparent 100%);
	/* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(19, 19, 19, 0.38)), color-stop(100%, transparent));
	/* Chrome,Safari4+ */
	background: -webkit-linear-gradient(bottom, rgba(19, 19, 19, 0.38) 0%, transparent 100%);
	/* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(bottom, rgba(19, 19, 19, 0.38) 0%, transparent 100%);
	/* Opera 11.10+ */
	background: -ms-linear-gradient(bottom, rgba(19, 19, 19, 0.38) 0%, transparent 100%);
	/* IE10+ */
	background: linear-gradient(to top, rgba(19, 19, 19, 0.38) 0%, transparent 100%);
	/* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 );
	/* IE6-9 */ }

.bg-overlay {
	position: relative; }
	.bg-overlay:before {
		content: "";
		top: 0;
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		transition: background-color 0.4s cubic-bezier(0.25, 0.74, 0.22, 0.99);
		background-color: rgba(19, 19, 19, 0.81); }
	.bg-overlay--color::before {
		background-color: rgba(0, 82, 248, 0.77); }

/* Section Dividers
-------------------------------------------------------*/
.top-divider {
	border-top: 1px solid #E9E9E9; }

.bottom-divider {
	border-bottom: 1px solid #E9E9E9; }

/* Animations
-------------------------------------------------------*/
.animate {
	position: relative;
	overflow: hidden; }
	.animate-container {
		transform: translateY(101%);
		transition: all 1s cubic-bezier(0.25, 0.74, 0.22, 0.99);
		transition-delay: .5s;
		will-change: transform; }
	.animate--animated .animate-container {
		transform: translateY(0); }

/* Alignment
-------------------------------------------------------*/
.alignleft,
.alignright {
	max-width: 100% !important;
	float: none; }

@media only screen and (min-width: 576px) {
	.alignleft {
		float: left;
		margin: .5em 2em 2em 0; }
	.alignright {
		float: right;
		margin: .5em 0 2em 2em; }
	.alignleft,
	.alignright {
		max-width: 40% !important; } }
	@media only screen and (min-width: 576px) and (max-width: 575px) {
		.alignleft,
		.alignright {
			margin-left: 0;
			margin-right: 0;
			float: none; } }

.alignnone {
	margin: .5em 2em; }

.aligncenter {
	display: block;
	margin: .5em auto; }

a img.aligncenter {
	display: block;
	margin-left: auto;
	margin-right: auto; }
