/*-------------------------------------------------------*/
/* Spacings
/*-------------------------------------------------------*/
.m-0 {  margin: 0!important; }
.p-0 { padding: 0!important; }
.mt-0 { margin-top: 0!important; }
.mt-8 { margin-top: 8px; }
.mt-16 { margin-top: 16px; }
.mt-24 { margin-top: 24px; }
.mt-32 { margin-top: 32px; }
.mt-40 { margin-top: 40px; }
.mt-48 { margin-top: 48px; }
.mt-56 { margin-top: 56px; }
.mt-64 { margin-top: 64px; }
.mt-72 { margin-top: 72px; }
.mt-80 { margin-top: 80px; }
.mb-0 { margin-bottom: 0!important; }
.mb-8 { margin-bottom: 8px; }
.mb-16 { margin-bottom: 16px; }
.mb-24 { margin-bottom: 24px; }
.mb-32 { margin-bottom: 32px; }
.mb-40 { margin-bottom: 40px; }
.mb-48 { margin-bottom: 48px; }
.mb-56 { margin-bottom: 56px; }
.mb-64 { margin-bottom: 64px; }
.mb-72 { margin-bottom: 72px; }
.mb-80 { margin-bottom: 80px; }
.pt-0 { padding-top: 0; }
.pt-8 { padding-top: 8px; }
.pt-16 { padding-top: 16px; }
.pt-24 { padding-top: 24px; }
.pt-32 { padding-top: 32px; }
.pt-40 { padding-top: 40px; }
.pt-48 { padding-top: 48px; }
.pt-56 { padding-top: 56px; }
.pt-64 { padding-top: 64px; }
.pt-72 { padding-top: 72px; }
.pt-80 { padding-top: 80px; }
.pt-96 { padding-top: 96px; }
.pb-0 { padding-bottom: 0; }
.pb-8 { padding-bottom: 8px; }
.pb-16 { padding-bottom: 16px; }
.pb-24 { padding-bottom: 24px; }
.pb-32 { padding-bottom: 32px; }
.pb-40 { padding-bottom: 40px; }
.pb-48 { padding-bottom: 48px; }
.pb-56 { padding-bottom: 56px; }
.pb-64 { padding-bottom: 64px; }
.pb-72 { padding-bottom: 72px; }
.pb-80 { padding-bottom: 80px; }
.pb-88 { padding-bottom: 88px; }
.pb-96 { padding-bottom: 96px; }