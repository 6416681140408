/*-------------------------------------------------------*/
/* Projects
/*-------------------------------------------------------*/
.project {
	position: relative;
	overflow: hidden;
	padding: 30px;

	@include bp-lg-up {
		padding: 70px;

		&:nth-child(2) {
			margin-top: 140px;
		}
	}	

	&-slide {
		margin: 0 19px;
	}

	&__container {
		position: relative;		
	}

	&__img-holder {
		position: relative;
		width: 100%;
		height: 100%;
		margin: 0;
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
	}

	&__img {
		width: 100%;
		@include transition($all);
		overflow: hidden;
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
	}

	&__title,
	&__category {
		opacity: 0;
		@include transition( opacity .2s cubic-bezier(.07,.98,0,.96) );
	}

	&__description-wrap {
		position: absolute;
		opacity: 0;
    bottom: 40px;
    left: 40px;		
		display: inline-block;
		width: 78%;
		max-width: 260px;
		-webkit-clip-path: inset(0 0 0 100%);
		clip-path: inset(0 0 0 100%);
		@include transition( opacity .2s .3s,clip-path 0s .3s );
	}

	&__description {
		position: relative;
		padding: 16px 20px;

		&:after {
			content: '';
			position: absolute;
			display: block;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background-color: #fff;
			-webkit-transform-origin: 0 50%;
			-moz-transform-origin: 0 50%;
			transform-origin: 0 50%;
			@include transform( scaleX(0) );
			@include transition( transform .35s cubic-bezier(.07,.98,0,.96) );
		}
	}
	
	&:hover {
		.project__description-wrap {
			opacity: 1;
			-webkit-clip-path: inset(0 0 0 0);
			clip-path: inset(0 0 0 0);
			-webkit-transition: -webkit-clip-path .8s cubic-bezier(.07,.98,0,.96);
			transition: clip-path .8s cubic-bezier(.07,.98,0,.96);
		}

		.project__description {
			&:after {
				-webkit-transform-origin: 100% 50%;
				-moz-transform-origin: 100% 50%;
				transform-origin: 100% 50%;
				@include transform( scaleX(1) );
				@include transition( transform .7s cubic-bezier(.07,.98,0,.96) );
			}
		}

		.project__title,
		.project__category {
			opacity: 1;
		}
	}

	&__url {
		display: block;
		position: relative;
		width: 100%;
		height: 100%;
	}

	&__title {
		font-size: 1.25rem;
		position: relative;
		z-index: 2;
	}

	&__category {
		position: relative;
		z-index: 2;
		display: inline-block;
		margin-top: 16px;
		font-size: 0.875rem;
		font-weight: 700;
	}
}


/* Grid
-------------------------------------------------------*/
.project-grid {
	margin-left: -30px;
	margin-right: -30px;

	@include bp-lg-up {
		margin-left: -70px;
		margin-right: -70px;
	}
}

.project-grid--5-col .project { width: 20%; }
.project-grid--4-col .project { width: 25%; }
.project-grid--3-col .project { width: 33.33333333%; }
.project-grid--2-col .project { width: 50%; }

@include bp-md-down {
	.project-grid .project {
		width: 100%;
	}
}
