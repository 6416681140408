/*-------------------------------------------------------*/
/* Contact Page
/*-------------------------------------------------------*/
.section-contact-info {
	padding-top: 24px;
	padding-bottom: 100px;	

	@include bp-lg-up {
		padding-bottom: 160px;
	}
}

.contact-info {
	font-family: $heading-font;
	font-weight: 700;
	margin-bottom: 48px;

	a,
	address,
	span {
		@include fontSize( 20px );
		line-height: 1.7;
	}

	a:hover {
		color: $secondary-color-dark;
	}

	&__title {
		@include fontSize( 24px );
		margin-bottom: 26px;
	}

	&__email,
	&__phone {
		display: block;
		color: $text-color;
	}
}