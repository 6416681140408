/*-------------------------------------------------------*/
/* Sliders
/*-------------------------------------------------------*/
.slick-slider{position:relative;display:block;box-sizing:border-box;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;-webkit-touch-callout:none;-khtml-user-select:none;-ms-touch-action:pan-y;touch-action:pan-y;-webkit-tap-highlight-color:transparent}.slick-list{position:relative;display:block;overflow:hidden;margin:0;padding:0}.slick-list:focus{outline:0}.slick-list.dragging{cursor:pointer;cursor:hand}.slick-slider .slick-list,.slick-slider .slick-track{-webkit-transform:translate3d(0,0,0);-moz-transform:translate3d(0,0,0);-ms-transform:translate3d(0,0,0);-o-transform:translate3d(0,0,0);transform:translate3d(0,0,0)}.slick-track{position:relative;top:0;left:0;display:block;margin-left:auto;margin-right:auto}.slick-track:after,.slick-track:before{display:table;content:''}.slick-track:after{clear:both}.slick-loading .slick-track{visibility:hidden}.slick-slide{display:none;float:left;height:100%;min-height:1px}[dir=rtl] .slick-slide{float:right}.slick-slide img{display:block}.slick-slide.slick-loading img{display:none}.slick-slide.dragging img{pointer-events:none}.slick-initialized .slick-slide{display:block}.slick-loading .slick-slide{visibility:hidden}.slick-vertical .slick-slide{display:block;height:auto;border:1px solid transparent}.slick-arrow.slick-hidden{display:none}

// Dots
.slick-dots {
	width: 100%;
	margin: 0;
	list-style: none;
	text-align: center;

	li {
		position: relative;
		display: inline-block;
		cursor: pointer;    

		margin: 0 7px;
		padding: 0;
		@include transition( $all );

		button {
			width: 14px;
			height: 14px;
			border-radius: 50%;
			font-size: 0;
			line-height: 0;
			padding: 0;
			outline: 0;
			border: 0;
			display: block;      
			cursor: pointer;
			color: transparent;
			background-color: $border-color;

			&:focus,
			&:hover {
				outline: 0;
				background-color: $primary-color;
				border-color: transparent;
			}
		}

		&.slick-active button,
		&.slick-active button:hover {
			background-color: $primary-color;
			border-color: transparent;
		}
	}

	& > li {
		display: inline-block;

		&:only-child {
			display: none;
		}
	}
}

// Arrows
.slick-slider .slick-prev {
	left: 0;
}

.slick-slider .slick-next {
	right: 0;
}

.slick-slider .slick-next,
.slick-slider .slick-prev {
	font-size: 0;
	line-height: 0;
	position: absolute;
	z-index: 1;
	top: 50%;
	display: block;
	padding: 0;
	transform: translateY(-50%);
	cursor: pointer;
	color: transparent;
	border: none;
	outline: none;
	background: transparent;

	&::before {
		font-family: dorya;
		font-size: 35px;
		line-height: 1;
		color: #fff;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
}

.slick-slider .slick-prev::before {
	content: "\e900";
}

.slick-slider .slick-next::before {
	content: "\e901";
}

.slick-slider-item {
	padding: 0 20px;
}